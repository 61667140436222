const translations = {
  en: {
    home: {
      // title: "Welcome to",
      title: "Welcome to Sheikh & Qazi",
      readMore: "Read more",
      mainHeading:
        "Sheikh and Qazi Advocates, Corporate and Tax Consultants is an International, Civil, Corporate, Commercial, Criminal, and Taxation law firm, which provides a full range of high-quality legal services including advisory work, merger, and acquisition transaction, consultancy, Litigation, and dispute resolution, to Pakistani and International businesses, organizations, and institutions. We have a team of specialized lawyers with high legal acumen.",

      subHeading:
        "In order for businesses to capitalise on existing opportunities and to ensure smooth operations, they require clear and strong commercial agreements. ",
      aboutSectionTitle: "What We Do",
      aboutSectionLegal: "Legal Services",
      aboutSectionLegal1:
        "  With our highly skilled and dynamic team of lawyers and       professionals, who practice law with passion and      integrity, we are able to provide both our business      clients and private clients with a wide range of seamless      legal services and strategic guidance across a broad range      of specialist practice areas, ensuring excellence in      client service at each stage.",
      aboutSectionLegal2:
        "Working collaboratively with our clients, we endeavour tirelessly with the aim to find innovative solutions to our clients' complex issues, whether those are high-value complex business transactional, regulatory, civil, constitutional or personal issues, or a combination of them.",
      aboutSectionLegal3:
        "We act as trusted advisers to our clients, committed to developing a deep understanding of their business, the industry and sector they operate in and accordingly providing solutions, customised to their specific needs, and which are also innovative, commercially focused and legally sound.",
      serviceHeading: "Our Services",
      servicesubHeading1: "Banking & Finance",
      servicesubHeading2: "Company Secretarial Services",
      servicesubHeading3: "Commercial",
      servicesubHeading4: "Corporate",
      servicesubHeading5: "Dispute Resolution / Litigation",
      servicesubHeading6: "Employment & Labour",
      servicesubHeading7: "Intellectual Property",
      servicesubHeading8: "Private Clients Services",
      servicesubHeading9: "Property & Real Estate",
      servicesubHeading10: "Family Law",
      servicesubHeading11: "Taxation",
      servicesubHeading12: "NGO - NPO",
    },
    navbar: {
      navHeading1: "Who We Are",
      navHeading2: "Practice Areas",
      navHeading3: "Our Professionals",
      navHeading4: "News & Insights",
      navHeading5: "Opportunities",
      navHeading6: "Contact",
      navHeading7: "Language",
      navHeading8: "Statutes",
    },
    whoWeAre: {
      /* Firm Overview Started */
      heading1: "Firm Overview",
      heading1Content1:
        "We offer specialist, commercial and pragmatic advice, to both employers and individuals, on a wide variety of employment and labour law matters. Working closely with the HR and senior management teams, we aim to make employment matters straight-forward thereby enabling our clients to take decisions confidently.",
      heading1Content2:
        "The various services offered, through our Employment & Labour practice group are:",
      contentList1:
        "Drafting of employment contracts, policies, handbooks, and procedures",
      contentList2:
        "Day-to-day advice on employee relations issues, including disciplinary issues, dismissals, and grievances",
      contentList3: "Business reorganizations, transfers, and redundancies",
      contentList4:
        "Director and senior management contracts negotiations and drafting",
      contentList5:
        "Advising and representation before employment tribunals and High Court in relation to unfair dismissals, restraint of trade, and other employment dispute",
      contentList6:
        "EOBI, SESSI and other statutory laws compliance and dispute resolution",
      heading1Content3:
        "In addition to providing a quality, practical legal services, we also provide bespoke trainings and seminars to the HR and management teams.",
      /* Firm Overview End */
      /* Client Centered Approach Started */
      heading2: "Client Centered Approach",
      heading2Content1:
        "In the present rapidly changing and competitive business environment, clients' demands and expectations from their lawyers are also changing. One area of clients' frustration, which has mired the legal service sector in cynicism, is the inaccessibility of the lawyers once the matter has been delegated by the client, coupled with the lack of responsiveness and timely advice. WE ARE HERE TO CHANGE THIS; THE STATUS QUO.",
      heading2Content2:
        "With a clear vision of becoming the 'law firm of the future', we take pride in claiming that we are focused squarely on our clients and their legal matters, being proactive and client-led from the outset of our engagement. Excellence in client service sits at the heart of everything we do, as we aim to develop lasting relationships with our clients.",
      heading2Content3:
        "Our watchword is ‘Client Value Proposition’, i.e. the value we can deliver to our clients. To this end, we have created a value-driven client service model, unique to the legal profession and a modern approach to providing, delivering and managing client and legal services. Our service model combines innovative client care protocols, legal matter management, and enhanced use of progressive technology solutions, thereby delivering tangible results for our clients in the form of consistent and efficient quality legal services, and improved communication and collaboration. In addition, we have invested significantly in solutions and technical resources, which are used by the top international law firms globally, in order to provide our clients with superior service at a par with international standards.",
      heading2Content4:
        "We are also able to distinguish ourselves, as client-driven, based on our ability to understanding the uniqueness of each of our client, rather than viewing them generically. We firmly believe that a particular strategy or solution for one client may not be suitable for another, even if they operate in similar sectors, and therefore we seek to bring creative insight and fresh approach to every matter.",
      heading2Content5:
        "Our legal strategy is devised after carefully listening to our clients’ needs and issues, and developing a sound understanding of the business of our client and the industry in which they operate. Once the best strategy has been devised, we aim to advice our client in a timely, transparent, friendly and straightforward manner, thereby allowing our clients to fully understand the rewards and risks associated with each option.",
      heading2Content6:
        "Moreover, our collaborative approach ensures that if a particular matter requires a unique set of skills or is multi-jurisdictional, we will be able to provide you with our recommended and trusted network of like-minded leading independent law firms and counsels, who share our values and provide expert advice and dedicated services ensuring that you receive the same level of client care as you do with us.",
      /* Client Centered Approach End */
      heading4: "Culture and Core Values",
      heading4Content1:
        "Our values are the foundation of our culture, providing a shared sense of direction and guidance for our people. Through putting our core values at the center stage and ensuring that these values are evident in everything we do and are reflected in every strand of the firm, we are able to accomplish our goal of being meaningful partners and trusted advisers to our clients.",
      heading4Content2:
        "The values which defines the lawyers and professionals at Sheikh & Qazi are:",

      clientContentList1: "APPROACHABLE",
      clientContentList2: "INTEGRITY",
      clientContentList3: "HIGHEST QUALITY WORK",
      clientContentList4: "PROFESSIONALISM",
      clientContentList5: "DEDICATION TO ENHACNING CLIENT VALUE",
      clientContentList6: "INNOVATIVE AND PROGRESSIVE",
      clientContentList7: "PURSUIT OF EXCELLENCE",
      heading4Content3:
        "Our core values guide us in the way we interact with clients, as well as amongst ourselves, the way we work, the way we make our decisions, and the way we assess and reflect on our performance.",
    },
    bankingFinance: {
      content1:
        "We offer a broad range of banking and financial legal services. We take pride in providing dynamic solutions and timely services to lenders and borrowers dealing with financing and security matters, ensuring that the transaction is completed on time.",
      content2:
        "Our team, with considerable expertise and experience across virtually all types of financing facility (funded and unfunded) and securitization, is regularly involved in representing clients with respect to structuring, negotiating and documenting sophisticated and complex financing transactions, in matters ranging from real estate and asset-based finance, lease finance, Islamic finance, multi-lender syndicated loans to general corporate, commercial and consumer secured or unsecured lending.",
      content3:
        "In addition, our lawyers have advised and assisted some of the leading local and international banks and financial institutions, including Non-Banking Financial Institutions (NBFIs), in producing and updating their standard template banking and finance documents, banking products development and standardised terms and conditions. We are able to provide legally and technically sound advice, as our team handles all banking and financing matters from both commercial viewpoint, as well as the regulatory aspect.",
      content4:
        "Our team is also highly proficient in Islamic financing, advising Modarabas and Islamic banks on their Islamic financing transactions, including structuring, documentation and securitization, as well as development of Shariah-compliant products. Our expertise in Islamic finance include structuring on: (a) Murabaha, (b) Mushraka / Diminishing Musharaka, (c) Ijara, and (d) Istisna. Our formidable understanding of the principle of Shariah-compliant banking, coupled with our knowledge of current scholars' interpretations and the evolution of market practice for Islamic financing, enables us to work closely with Islamic financial institutions in devising innovative and leading-edge financing structures and products, ensuring that risks are minimized and our clients are able to leverage the opportunities available.",
      content5:
        "We assist and advise our clients in structuring, documenting and ultimately closing a wide range of financing transactions including:",
      content6:
        "We also provide additional support to clients through regular seminars, workshops, briefings and in-house training.",
      contentList1: "Corporate Lending & Securitization",
      contentList2: "Asset-Based Lending",
      contentList3: "Receivables Financing",
      contentList4: "Lease Financing",
      contentList5: "Bilateral and Syndicate Lending",
      contentList6: "Islamic Finance",
      contentList7: "General Commercial Lending",
      heading1: "  Corporate Finance",
      heading1Content1:
        "Our lawyers regularly advise borrowers and lenders on the full spectrum of secured and unsecured corporate and commercial lending transactions, including but not limited to, bilateral and syndicate asset-based financing, financing of private business acquisitions (by way of both share and asset purchases), receivables financing, bridge financing and real estate finance, in line with the legal and regulatory framework in Pakistan.",
      heading1Content2:
        "With a combination of skills, breadth of experience and geographical reach, we are strongly positioned in preparing standard and unique loan documentation and security structures with a commitment of providing the best possible service for our clients, however big or small the project. In each transaction that we are involved in, we are focused on closing the deal in an effective and timely manner, whilst protecting each side’s interests.",
      heading2: "  Banking Dispute & Litigation",
      heading2Content1:
        "Our litigation and dispute resolution lawyers are highly experienced in all aspects of banking litigation and enforcement of securities. We provide strategic, cost-efficient and seamless service to banks and financial institutions, before the Banking Courts as well as Sindh High Court at Karachi, in respect of recovery of finances by way of enforcement of corporate and consumer finance documents and related securities.",
      heading2Content2:
        "In addition, our lawyers also provide ongoing counsel to financial institutions on governmental regulatory and enforcement matters (e.g. FIA, SRB, SBP etc.), litigation and transaction risks, and dispute-resolution mechanisms.",

      heading3: "   Islamic Finance",
      heading3Content1:
        "Our banking and finance lawyers possess specialist understanding of the principles and requirements underpinning Shariah-compliant finance, and working closely with the Shariah advisors of leading Islamic banks and Modarabas, have advised on structuring of complex syndicate Islamic financing transactions, investments, and products.",
      heading3Content2:
        "Our expertise in Islamic Finance includes, but is not limited to, the following:",
      heading3contentlist1: "Sukuk",
      heading3contentlist2: "Murabaha",
      heading3contentlist3: "Musharaka, including Diminishing Musharaka",
      heading3contentlist4: "Ijarah",
      heading3contentlist5: "Istisna",
    },
    commerical: {
      content1:
        "In order for businesses to capitalise on existing opportunities and to ensure smooth operations, they require clear and strong commercial agreements. A well drafted commercial contract mitigates the risk for dispute and comprehensively sets out the obligations of the parties, thereby ensuring certainty in business relations.",
      content2:
        "As part of its commercial practice, Sheikh & Qazi provides broad legal services across a range of industry sectors. Our local and international lawyers, with extensive experience in advising national and international business clients, are able to deliver clear and intelligent commercial contracts, contemporaneously providing guidance on best practices with regards to such contracts, with a view to ensuring that our clients can achieve their strategic, commercial and business goals.",
      content3:
        "We can deal with commercial contracts of virtually all descriptions, including but not limited to:",
      contentList1: "negotiating and drafting commercial contracts",
      contentList2:
        "cross-border joint venture, partnership and collaboration agreements",
      contentList3: "consultancy agreements",
      contentList4: "IT agreements",
      contentList5: "corporate agreements (shareholders' agreements, etc.)",
      contentList6: "supply and distribution agreements",
      contentList7: "franchising agreements",
      contentList8: "service agreements",
      contentList9: "sale and purchase agreements",
      contentList10: "standard business terms and conditions",
      content4:
        "Our lawyers understand the value of enhancing the clients' business, through risk management and leveraging opportunities, and accordingly address all commercial matters meticulously with useful business insight.",
    },
    propertyRealState: {
      content1:
        "With considerable experience and expertise in all areas of property law, whether you are a large organisation looking for services in relation to complex construction projects, a banking company wanting to vet a property as collateral for financing facility or a private client seeking to complete a residential sale or purchase, we can provide a full range of transactional, advisory and dispute resolution legal services in property matters.",
      content2: "Our areas of expertise in property law includes:",
      contentList1:
        "Sale and purchase of residential and commercial properties",
      contentList2:
        "Advising banks and financial institutions, through diligent property reviews for real estate financing facilities and security collateral",
      contentList3: "All aspects of landlord and tenant matters",
      contentList4: "Property dispute resolution",
      contentList5:
        "Assisting with recoveries for lenders when borrowers default",
    },
    INTELLECTUALPROPERTY: {
      content1:
        "We offer a broad range of services in the trademark area of Intellectual Property Law. We advise clients of all types, from start-up businesses to established organisations, on how to protect, maintain and enforce their trademark and brand identity.",
      content2:
        "We advise on a full range of contentious and non-contentious matters including:",
      contentList1: "Trademark search and trademark registration applications",
      contentList2:
        "Trademark licensing, franchising, assignment, and distribution agreements",
      contentList3: "Registered and unregistered trade mark rights, and",
      contentList4:
        "Assisting clients with trade mark opposition and cancellation actions",
    },
    privateClientServices: {
      content1:
        "We provide comprehensive and cost-effective legal services and advice to individuals, ranging from entrepreneurs and landowners to High Net Worth Individuals (HNWI). Our lawyers provide strategic, high-quality, and practical legal solutions to your issues relating to:",
      contentList1: "Inheritance and succession matters",
      contentList2:
        "Personal Immigration to UK (Work, Study, Investment, Family)",
      contentList3:
        "Residential / commercial property acquisition and conveyancing",
      contentList4: "Family law",
      contentList5: "Commercial matters and transactions",
      heading1: " Succession & Estate Administration",
      heading1Content1:
        "We offer extensive technical expertise to High Net Worth Individuals, in respect of inheritance and succession matters involving complex issues arising out of local and cross-border estate left by deceased family member. We have a strong and specialist knowledge of the conflict of laws issues which arise in situations where assets and estate of the deceased are in different jurisdictions (countries) and are able to help you navigate the complexities with efficient and compassionate advice.",
    },
    employmentLabour: {
      content1:
        "We offer specialist, commercial and pragmatic advice, to both employers and individuals, on a wide variety of employment and labour law matters. Working closely with the HR and senior management teams, we aim to make employment matters straight-forward thereby enabling our clients to take decisions confidently. ",
      content2:
        "The various services offered, through our Employment & Labour practice group are:",
      content3:
        "In addition to providing a quality, practical legal services, we also provide bespoke trainings and seminars to the HR and management teams.",
      contentList1:
        "Drafting of employment contracts, policies, handbooks, and procedures",
      contentList2:
        "Day-to-day advice on employee relations issues, including disciplinary issues, dismissals, and grievances",
      contentList3: "Business reorganizations, transfers, and redundancies",
      contentList4:
        "Director and senior management contracts negotiations and drafting",
      contentList5:
        "Advising and representation before employment tribunals and High Court in relation to unfair dismissals, restraint of trade, and other employment dispute",
      contentList6:
        "EOBI, SESSI and other statutory laws compliance and dispute resolution",
    },
    companySecretarial: {
      content1:
        "In order to ensure that companies are in compliance with the statutory framework governing companies, and to avoid penalties and regulatory issues due to non-compliance, we assist both local and foreign companies with a range of company secretarial and administrative services, including:",
      contentList1: "Advising on all aspects of company law and compliance",
      contentList2: "Company formation",
      contentList3:
        "Preparation and filing of statutory forms and resolutions, e.g. appointment and resignation of officers, annual returns, etc.",
      contentList4:
        "Ensuring compliance with statutory deadlines and procedures",
      contentList5:
        "Processing transfer of shares and issuing share certificates",
      contentList6: "If required, preparation of resolutions and board minutes",
      contentList7:
        "Maintaining and updating registers and statutory records of the company",
      contentList8: "Act as proxies and nominee shareholders",
      contentList9: "Keeping you informed of important compliance deadlines",
      content2:
        "The service is provided by trained and professional staff, under the supervision of our lawyers in the Corporate team.",
      heading1: "Company Formation",
      heading1Content1:
        "We are regularly instructed by both local and foreign companies / promoters to provide assistance with the incorporation of a company / foreign company. Accordingly, we provide end-to-end service with regards to the incorporation of:",
      heading1List1: "private company",
      heading1List2: "public company and",
      heading1List3:
        "not-for-profit company (Section 42 - Companies Act 2017).",
      heading1Content2:
        "We have also advised clients with regards to establishing trade organisations with the Directorate General of Trade Organisations (DGTO), under the Trade Organisation Act 2013.",
      heading1Content3:
        "Our methodological approach to company incorporation means that we work closely with our clients, understanding their business and sector in which they wish to operate, followed by providing strategic guidance and undertaking the applicable procedure to ensure effective and timely completion of the incorporation process.",
    },
    corporate: {
      content1:
        "Our team has strong expertise in business and corporate law matters, providing distinctively energetic, dynamic, and pragmatic solutions to our clients in connection with complex transactional and corporate financing matters across a range of industry sectors. Our lawyers have advised and acted for a diverse client base, from start-ups to multinationals companies, and investors / shareholders through to family-run businesses, providing market-leading knowledge and facilitating corporate transactions and financing needs of our clients.",
      content2:
        "We understand our clients’ business goals and priorities and therefore, with our 'one-firm' approach, we are able to work across legal disciplines to deliver the optimum solution for their organisations in a cost-effective manner.",
      content3:
        "With our specialist legal skills combined with strong business acumen, and a network of international lawyers at our associated office in the UK providing international perspective and support, we provide exceptional strategic advisory and legal counsel on a range of corporate matters, including:",
      contentList1: "Negotiations and Pre-Transaction Agreements",
      contentList2: "Mergers and Acquisitions (M&As)",
      contentList3: "Legal Due Diligence (Buy-Side or Sell-Side)",
      contentList4: "Private Equity and Venture Capital Transactions",
      contentList5: "Joint Ventures and Strategic Alliances",
      contentList6: "Regulatory Compliance",
      contentList7: "Shareholders' Agreements, Joint Venture Transactions",
      contentList8: "Winding up and Liquidation",
      contentList9: "Corporate Governance",
      content4:
        "We regularly advise entrepreneurs, start-ups, leading private and family businesses, and high net worth individuals and their businesses on the day-to-day matters of managing and operating companies in line with the legal and regulatory framework governing companies, thereby assisting them to navigate through complex, industry specific matters.",
      heading1: "Corporate Advisory & Negotiation",
      heading1Content1:
        "Our corporate lawyers are recognized for their legal skills and the ability to navigate complex corporate matters with diligence. Due to our lawyers having an attentive and proactive approach to our clients' business needs, we are regularly engaged by a diverse client base, from start-ups to multinationals, family-run businesses through to non-profit organizations, and our counsel is sought on a range of company law matters, as well as representing clients in negotiations on the proposed deal and transaction documents before the other party to achieve the best possible outcome.",
      heading1Content2:
        "Our corporate advisory, building on extensive local and international experience, encompasses a broad range of areas, including:",
      heading1ContentList1: "raising financing (through equity and/or debt)",
      heading1ContentList2: "shareholders' agreements and dispute resolution",
      heading1ContentList3: "directors' duties and liabilities",
      heading1ContentList4: "SECP and other regulatory compliance",
      heading1ContentList5: "corporate governance issues and compliance",
      heading1ContentList6: "board policies and boardroom dispute",
      heading1ContentList7: "C-suite service contracts",
      heading1Content3:
        "We complement our specialist legal expertise with a client-centric approach, ensuring exceptional client service and value. In addition, we have established a suite of training programmes and seminars to keep our clients up-to-date with latest developments in regulatory regime as well as enhancing the understanding of our clients in fundamental business law principles.",
      heading2: "Family Business",
      heading2Content1:
        "Our team, bringing together skills from a variety of legal disciplines, is highly proficient in providing comprehensive legal services across a range of key industries to family-owned and controlled businesses. Our multidisciplinary and meticulous approach ensures that we develop a formidable understanding of your business and the objectives of your family before providing clear, intelligent and pragmatic advice to manage and safeguard your assets and support the long-term future for your business and family.",
      heading2Content2:
        "Our expertise and services for family-owned businesses of all sizes across a range of sectors include:",
      heading2Content3:
        "We engage with our clients to strategise in a timely manner and review the plan regularly.",
      heading2ContentList1: "Business Succession Planning",
      heading2ContentList2: "Family and Business Governance Agreements",
      heading2ContentList3: "Business Structuring / Restructuring",
      heading2ContentList4: "Shareholder and Constitutional Arrangements",
      heading2ContentList5: "Family Dispute Management and Resolution",
      heading2ContentList6: "Shariah-compliant Wills and Inheritance Planning",
      heading2Content4:
        "We engage with our clients to strategise in a timely manner and review the plan regularly.",
      heading3: "Mergers & Acquisitions (M&As)",
      heading3Content1:
        "Our results-oriented team is focused on making transactions fast, smooth, and cost-efficient. Our transaction focus in the M&AS practice area is predominantly private company mergers and acquisitions involving complex structure and unique considerations, although we also routinely advise on business restructuring and reorganisation. Our goal is to deliver exceptional value throughout each phase of the transaction from diligence, structuring, and negotiation through execution and closing, providing seamless service to achieve our clients' commercial objectives. Our service in this area includes, but is not limited to:",
      heading3contentlist1: "Deal Negotiations;",
      heading3contentlist2: "Letter of Intent / Head of Terms;",
      heading3contentlist3: "Due Diligence;",
      heading3contentlist4: "Key Documents Preparation;",
      heading3contentlist5: "Disclosures;",
      heading3contentlist6: "Regulatory Compliance; and",
      heading3contentlist7: "Post-completion formalities.",
      heading3Content2:
        "Our corporate lawyers, with strong support from other practice areas and a network of specialist consultants, also regularly advise on the incorporation and structuring of joint venture arrangements between corporate entities and institutional investors, including SPV formation, funding, commercial terms, tax implications, termination and dispute management.",
      heading4: "  Startups & Emerging Companies",
      heading4Content1:
        "We represent and advise start-up and early stage companies across diverse sectors, and work with these companies from formation through to their development, growth and expansion stages. Working closely with these emerging companies, we advise on a broad spectrum of matters including selecting a business structure, assisting with their financing needs, whether debt or equity, and subsequent exit strategy and investment realization. Whatever the needs of a startup and early stage companies are, we have the capabilities and practical experience to provide the legal services essential to their success.",
    },
    disputeResolution: {
      content1:
        "Sheikh & Qazi's litigation and dispute resolution specialists can help you resolve your complex and stressful contentious matters, in a broad spectrum of legal areas. We are passionately committed to providing the highest quality of legal representation and litigation to our clients. We work closely with our clients to assess their needs and objectives at the outset of the dispute, followed by providing practical advice on the merits of the case and exploring all available options for its resolution, including offering our clients our services of information negotiations and mediation.",
      content2:
        "Where out-of-court settlement is not an option, we are tenacious and effective litigators, experienced in handling challenging litigation with a multifaceted approach. We take pride in claiming that our standards of client care, quality of advice, and the manner of delivery is equally at par with our services in other practice areas. This means that we are highly responsive, approachable, and act quickly to provide clear advice, ensuring that our clients are always well informed with the status and progress of their matter. In addition, with our innovative litigation management tools, we are able to enhance efficiencies and prompt delivery of communication and correspondence in all cases.",
      content3:
        "We provide legal representation and litigation services before the District Courts at Karachi, Sindh High Court at Karachi, as well as appearing before quasi-judicial forums and tribunals, in the following areas:",
      contentList1: "banking dispute and recovery of finances",
      contentList2: "civil dispute",
      contentList3: "commercial and contractual dispute",
      contentList4: "constitutional law and statutory rights enforcement",
      contentList5: "defamation and reputation management",
      contentList6: "employment dispute",
      contentList7: "intellectual property dispute",
      contentList8: "property and real estate dispute",
      content4:
        "Our client base comprises of public and private companies, banks and financial institutions, high net worth individuals, family businesses, non-profit organisations, and expatriate and overseas Pakistani nationals.",
      content5:
        "We are also able to effectively handle cross-border litigation in the UK, through our litigation experts at our associated office in the UK.",
      heading1: "Banking Dispute & Litigation",
      heading1Content1:
        "We represent banks and financial institutions in a variety of contentious matters, but primarily act for recovery of finances availed by consumer and corporate clients, under financing and security documents. Our approach is swift and effective, working closely with the in-house counsels of the banks, ensuring that any external risks are adequately mitigated.",
      heading2: "Civil Dispute & Litigation",
      heading2Content1:
        "We act for businesses and private clients in respect of dispute arising out of their civil rights, seeking to claim damages, specific performance or injunction. The civil right may accrue in a variety of areas, including landlord and tenants dispute, family law dispute, money claims, defamation, partnership dispute etc. We adopt a results-oriented approach and discuss the merits of the claim with the client before undertaking to represent them.",
      heading2Content2:
        "Once we are engaged, we adopt an agile and effective legal strategy to ensure the most favourable outcome for our client. Where necessary, we can act quickly to obtain injunctions to prevent any future damage to our client. One distinguishing element of our firm is that we keep our clients apprised of the status of the case on a frequent basis, by sharing case updates, upcoming hearings, and all necessary information so that our clients are at ease.",
      heading3: "Commercial & Contractual Dispute",
      heading3Content1:
        "We understand that contractual and commercial dispute can be damaging to our business and private clients alike and requires careful handling and urgent response. Our team of litigation lawyers are experienced in handling commercial and contractual dispute in a diligent and responsive manner.",
      heading3Content2:
        "We represent clients before the High Courts and District Courts at Karachi, in dispute arising out of breach of contracts and commercial dealings, shareholder dispute, professional negligence, regulatory breaches, breaches of employment contracts, breach of intellectual property rights etc. In the event of a dispute, we help our clients navigate seamlessly through the full life cycle of the litigation case and ensure that our clients are kept updated at each stage of the matter.",
      heading4: "Constitutional Rights & Litigation",
      heading4Content1:
        "We act for clients in matters concerning complex mix of legal, commercial and constitutional factors centered around issues of fairness and justice, as provided under the Constitution of Islamic Republic of Pakistani, 1973. We advise our clients on their constitutional rights, as well as the remedies available to them, and are able to expeditiously initiate writ / constitutional petitions before the High Court under the applicable provision of Article 199 of the Constitution.",
      heading4Content2:
        "In addition, we also invoke the constitutional jurisdiction of the High Court, in matters pertaining to protection or preservation of the rights of the general public, through our Public Interest Litigation division.",
      heading5: "Property & Real Estate Dispute",
      heading5Content1:
        "Our litigation team is well equipped to represent business and private clients in dispute arising out of property and real estate related transactions. We advise on diverse array of matters in this area, including commercial and residential landlord and tenant dispute, contractual interpretation (agreements for sale, sale deeds), planning and construction dispute etc. Our litigators are approachable, persuasive, and possess excellent advocacy skills and are dedicated to achieve the best possible outcome for our clients.",
    },
    familyLaw: {
      content1:
        "At S&Q, our enthusiastic and meticulous team provides the paramount treatment to the client by given best possible advises on their disputes just like to make them comfortable and assured that the remedies we put forward toward them are best in their interest. Hence, any family case at the district level is treated as a case of the apex court. Be it a maintenance dispute, divorce dispute, or custody matter, S&Q can be counted on to understand and to make things right.",
      content2:
        "The following are our key areas of practice before the Pakistani family courts:",
      heading1: "Dissolution of Marriage by way of Divorce/Khula:",
      heading1Content1:
        "The end of a marriage is always a big step. But it can be bittersweet if done correctly. We truly believe in given the right advice to our client in dissolution of marriage as one has to fully inform with the consequences of the same after such step.",
      heading1Content2:
        "S&Q believes in providing the most sensitive legal care and client dealing when working with a divorce/khula case. It is important to mention that khula is not the only option for a woman to dissolve her marriage. As Muslim family law Ordinance provides 8 grounds where a client can seek divorce through court and khula is one of them not only one which is not known by most clients. Our Counsels make sure to advise you the best option which is in your best interest",
      heading2: "Maintenance:",
      heading2Content1:
        "Maintenance is the one of the most complex dispute which required cogent evidences whether to prove allegation or decline the same. We at S&Q are advocates that fight aggressively for you before the courts, so that you are best served.",
      heading2Content2:
        "This is one of the area of practice in which our lawyers have amazing command to defend you whether to appear from wife side or husband. We always stands for our client welfare.",
      heading3: "Custody/Guardianship:",
      heading3Content1:
        "To us, your child is more valuable than any piece of property can ever be. We fight for your legal rights, keeping your interests above and beyond any other commitment. We do everything in our power to make sure that your child is given the correct legal recourse that would benefit his/her future in the long-term.        Our expert family lawyers make sure to fight for best possible maintenance allowance for our clients and minors.        ",
      heading3Content2:
        "This is one of the area of practice in which our lawyers have amazing command to defend you whether to appear from wife side or husband. We always stands for our client welfare.",
      heading3Content3:
        "Not only for mothers,  we strongly contests on behalf of fathers when they is on right to make sure he gets justice against any allegation which is not true in its essence.",
      heading3Content4:
        "Disputes which can be put forward before courts in Guardianship matters are:",
      contentList1: "Meeting Rights ",
      contentList2: "Custody",
      contentList3: "Protection",
      contentList4: "Temporary Custody",
      heading4: "Domestic Violence:",
      heading4Content1:
        "Domestic violence by a spouse, parent, child or sibling is becoming more and more common. Domestic violence encompasses acts of physical, psychological, sexual, verbal and financial undue force. While this area can be treated as a criminal case in more severe scenarios, the matter can be taken before a family bench too. Given the fact that Pakistan has special legislation against domestic abuse, we levy this while unequivocally arguing your case so that you can get the best possible remedy. This can take the form of damages, interim and restraining orders, khulas/divorce and obtaining FIRs. Unfortunately, clients lack the awareness of the same due to which there are many people who are unheard. We make sure to spread Awareness and deter the perpetrator of the violence by taking them before court.",
    },
    taxation: {
      content1:
        "Sheikh and Qazi Advocates, Corporate and Tax Consultants is supervised and managed by a team of specialized lawyers with high legal acumens and armed with top-notch skills in direct and indirect taxation. As a tax consultant, we assist our respected clients in tax planning to enhance the tax efficiency within legal parameters in addition to that, providing planning and advising on strategizing tax implications.",
      content2:
        "We believe in developing a close relationship with our respected clients, which enables us to understand the business process which is of core importance in recommending a suitable tax-efficient business model. We provide a work environment for our people that attracts, develops, and challenges the most talented professionals. We handle matters of every type and size ranging from individuals to multinational organizations.",
      content3:
        "The services offered by the S&Q can be categorized into the following segments;  ",
      heading1: "Direct Taxation Services:",
      heading2: "Income Tax Advisory Services",
      heading2contentList1:
        "Advice and opinions on the matters of Income Tax both written and oral",
      heading2contentList2:
        "Advice and updating of new tax laws applicable to the company, AOP, and individual",
      heading3: "Income Tax Compliance Services",
      heading3contentList1:
        "E-filing of withholding tax statements on the month-to-month basis within the given timeframe",
      heading3contentList2:
        "The advice in computing advance income tax on a quarterly basis and the related intimation to the department",
      heading3contentList3:
        "The advice in computing capital gain tax and the related intimation to the department",
      heading3contentList4:
        "Preparation of income tax provisions of the company",
      heading3contentList5:
        "Computation of total income of the taxable person and tax thereon",
      heading3contentList6:
        "Preparation and filing of Annual Income Tax Return of the Company, AOP",
      heading3contentList7:
        "Compliance of legal notices in connection with withholding tax audit proceedings under Income Tax Ordinance, 2001",
      heading3contentList8:
        "Compliance of legal notices in connection with income tax amendments proceedings",
      heading3contentList9: "Preparation of income tax return of an individual",
      heading4: "Indirect Taxation Services (Federal and Provincial Taxes):",
      heading4contentList1: "Sales tax return review and advisory services",
      heading4contentList2:
        "Compliance of legal notices with respect to the sales tax return and representations thereof",
      heading4contentList3: "Provincial return and advisory services",
      heading4contentList4: "Compliance of monthly sales tax return",
      heading4contentList5:
        "Making representations before the Commissioner of Inland Revenue (Appeals) and/or Appellate Tribunal and/or higher authorities",
      heading4contentList6: "Preparation and follow up of refund cases",
      heading4contentList7: "Representation for sales tax audit proceedings",
      heading5:"INDIRECT TAXATION SERVICES"
    },
    OurPros: {
      content:
        "At Sheikh & Qazi, our clients range from multinational companies to sole proprietorships. Regardless of size and sector, we treat every client like our most important one, providing strategic legal counsel that is tailored to your business and the unique issues you face.",
    },
    aboutData: {
      heading1: "About Sheikh & Qazi",
      heading2: "OUR EXPERTISE",
      heading3: "CONT...",
      content1:
        "We offer specialist, commercial and pragmatic advice, to both employers and individuals, on a wide variety of employment and labour law matters.",
      content2: "Contact Us",
      content3: " Copyright Sheikh & Qazi. All Rights Reserved",

      content4: "   Develop by",
      content5: "Phone",
      content6: "   Email",
      content7: "   Mobile",
      content8: "   Our Address",
      content9: "   Timing",
      content10: "   Call Us",
      contentSchedule1: "   Monday to Friday",
      contentSchedule2: "   Saturday",
    },
    careers: {
      content1:
        "Sheikh & Qazi has a workplace that is professional, team-oriented, and collegial where diverse talents and skills are highly valued. At Sheikh & Qazi, you will be part of our legal team and will be encouraged to innovate, strategize and collaborate on complex legal matters for sophisticated clients.",
    },
    npo:{
      heading1: "NPO & NGO REGISTRATION",
      content1:
      "S & Q provides comprehensive legal services to non-profit organizations and associations in the Islamic Republic of Pakistan. We provide services to businesses, institutions, educational, social, religious, individuals and families seeking to accomplish non-profit objectives.  In Pakistan there are numbers of laws for registration of non-profit organizations including the prominent Acts and Ordinances as The Societies Registration Act, 1860, The Trusts Act, 1882, Registration of non-profit Companies under section 42 of the Companies Act, 2017, The Co-operative Societies Act, 1925, Trade Organizations Ordinance, 2007, Non-Profit Public Benefit Organizations (Governance and Support) Act, 2003, Mussalman Waqf Act, 1923, Charitable Endowments Act, 1923.",
      content2:"S & Q offers a broad spectrum of legal services to NPO’s in Pakistan including following:",
      heading1contentList1:   "Registration of Non-profit Organizations/Non-Governmental Organizations",
      heading1contentList2:   "Preparation of documents",
      heading1contentList3:   "Advising non-profit board members of their duties & responsibilities",
      heading1contentList4:   "Advising individuals and entities with respect to charitable giving matters",
      heading1contentList5:   "Advising NPO’s in obtaining tax exemptions",
      heading1contentList6:   "Representing & defending all types of NPO’s in mediation, arbitration, litigation & similar matters",
      heading1contentList7:   "Advising on real estate transactions, environmental etc. issues",
      heading1contentList8:   "Advice NGO’s in their fund raising efforts",
      heading1contentList9:   "Advising on Intellectual property issues, applying for and protecting copyrights or trademarks and obtaining licenses to use copyrighted materials",
      heading1contentList10:  "Drafting of employment agreements",
      heading1contentList11:  "Dissolution of NPOs/NGOs",
      content3:"Contact us for advise on which type of NGO / NPO best suites your needs.",
    }
    
  },

  ch: {
    home: {
      // title: "歡迎來到賽義德＆謝赫（Sayyid ＆ Shaykh）",
      title: "歡迎來到賽義德＆謝赫",
      readMore: "阅读更多",
      mainHeading:
        "Sheikh and Qazi Advocates, Corporate and Tax Consultants 是一家国际、民事、公司、商业、刑事和税务律师事务所，提供全方位的高质量法律服务，包括咨询工作、并购交易、咨询、诉讼和争议解决，巴基斯坦和国际企业、组织和机构。我们拥有一支具有高度法律敏锐度的专业律师团队。",
      subHeading:
        "為了使企業能夠利用現有機會並確保平穩運行，它們需要明確且強有力的商業協議。",
      aboutSectionTitle: "我們所做的",
      aboutSectionLegal: "法律服務",
      aboutSectionLegal1:
        "我們擁有一支充滿激情和正直的律師事務所的高技能，充滿活力的律師和專業人員團隊，我們能夠為我們的商業客戶和私人客戶提供廣泛的無縫法律服務和廣泛的專業服務的戰略指導 領域，確保每個階段的客戶服務卓越。",
      aboutSectionLegal2:
        "與客戶合作，我們不懈努力，旨在為客戶的複雜問題找到創新的解決方案，無論這些問題是高價值的複雜業務交易，法規，民事，憲法或個人問題，還是它們的結合。",
      aboutSectionLegal3:
        "我們作為客戶的值得信賴的顧問，致力於加深對他們的業務，他們所從事的行業和行業的了解，並相應地提供針對其特定需求量身定制的解決方案，並且這些解決方案也具有創新性，以商業為重點並且在法律上是合理的。",
      serviceHeading: "我們的服務",
      servicesubHeading1: "銀行與金融",
      servicesubHeading2: "公司秘書服務",
      servicesubHeading3: "商業的",
      servicesubHeading4: "公司的",
      servicesubHeading5: "爭議解決/訴訟",
      servicesubHeading6: "就業與勞工",
      servicesubHeading7: "知識產權",
      servicesubHeading8: "私人客戶服務",
      servicesubHeading9: "物業與房地產",
      servicesubHeading10: "家规",
      servicesubHeading11: "税收",
      servicesubHeading12: "非政府组织-非营利组织",
    },
    navbar: {
      navHeading1: "我們是誰",
      navHeading2: "執業領域",
      navHeading3: "我們的專業人員",
      navHeading4: "新聞與見解",
      navHeading5: "机会",
      navHeading6: "接觸",
      navHeading7: "語",
      navHeading8: "法规",
    },
    whoWeAre: {
      /* Firm Overview Started */
      heading1: "公司概況",
      heading1Content1:
        "我們為雇主和個人就各種就業和勞動法事務提供專業，商業和務實的建議。 我們與人力資源和高級管理團隊緊密合作，旨在使僱用事宜變得直截了當，從而使我們的客戶能夠自信地做出決策。",
      heading1Content2: "通過我們的就業和勞工實踐小組提供的各種服務是：",
      contentList1: "起草就業合同，政策，手冊和程序",
      contentList2: "關於員工關係問題的日常建議，包括紀律問題，解僱和申訴",
      contentList3: "業務重組，轉移和裁員",
      contentList4: "董事和高級管理人員合同談判和起草",
      contentList5:
        "就不正當解僱，限制貿易和其他僱傭糾紛向就業法庭和高等法院提供諮詢和代理",
      contentList6: "EOBI，SESSI和其他成文法合規和爭議解決",
      heading1Content3:
        "除了提供優質，實用的法律服務外，我們還為人力資源和管理團隊提供定制培訓和研討會。",
      /* Firm Overview End */
      /* Client Centered Approach Started */
      heading2: "以客戶為中心的方法",
      heading2Content1:
        "在當今瞬息萬變，競爭激烈的商業環境中，客戶對律師的要求和期望也在變化。 令客戶沮喪的一個方面是犬儒主義中的法律服務部門，這是一旦客戶委託此事後律師無法與他們聯繫，以及缺乏響應和及時的建議。 我們將在此進行更改； 狀態現狀。",
      heading2Content2:
        "擁有成為“未來律師事務所”的清晰願景，我們自豪地宣稱我們從一開始就積極主動地以客戶為主導，專注於客戶及其法律事務。 我們致力於與客戶建立持久的關係，因此卓越的客戶服務是我們所做工作的核心。",
      heading2Content3:
        "我們的口號是“客戶價值主張”，即我們可以為客戶提供的價值。 為此，我們創建了價值驅動的客戶服務模型，這是法律專業人士所獨有的，並且提供了提供，交付和管理客戶及法律服務的現代方法。 我們的服務模式結合了創新的客戶服務協議，法律事務管理以及對先進技術解決方案的更多使用，從而以一致，高效的優質法律服務以及改善的溝通與協作的形式為我們的客戶提供了切實的成果。 此外，我們在解決方案和技術資源上進行了大量投資，這些解決方案和技術資源已為全球頂級國際律師事務所所採用，目的是為我們的客戶提供與國際標準相當的優質服務。",
      heading2Content4:
        "我們還可以基於我們理解每個客戶的獨特性的能力（而不是一般性地查看他們），以客戶為導向來區分自己。 我們堅信，針對一個客戶的特定策略或解決方案即使在相似的行業中運作，也可能不適合另一個客戶，因此，我們尋求為每件事提供創新的見解和新穎的方法。",
      heading2Content5:
        "我們的法律策略是在認真聽取客戶的需求和問題，並對客戶的業務及其所從事的行業形成深刻了解之後製定的。 一旦制定了最佳策略，我們旨在以及時，透明，友好和直接的方式為客戶提供建議，從而使我們的客戶充分了解與每種選擇相關的收益和風險。",
      heading2Content6:
        "此外，我們的協作方法可確保如果特定問題需要一套獨特的技能或具有多個司法管轄區，我們將能夠為您提供我們推薦並值得信賴的，由志同道合的領先獨立律師事務所和律師組成的網絡，他們共享我們的經驗。 重視並提供專家建議和熱忱的服務，以確保您獲得與我們相同水平的客戶服務。",
      /* Client Centered Approach End */
      heading4: "文化與核心價值觀",
      heading4Content1:
        "我們的價值觀是我們文化的基礎，為我們的人民提供了共同的方向和指導感。 通過將我們的核心價值觀置於中心位置，並確保這些價值觀在我們所做的一切工作中顯而易見，並在公司的每一個方面都得到體現，我們就能實現成為有意義的合作夥伴和客戶值得信賴的顧問的目標。",
      heading4Content2: "定義Sheikh & Qazi的律師和專業人士的價值觀是：",
      clientContentList1: "可接近的",
      clientContentList2: "正直",
      clientContentList3: "最高質量的工作",
      clientContentList4: "專業精神",
      clientContentList5: "致力於提升客戶價值",
      clientContentList6: "創新進取",
      clientContentList7: "追求卓越",
      heading4Content3:
        "我們的核心價值觀指導我們與客戶互動的方式，以及與客戶之間的互動方式，工作方式，決策方式以及評估和反思績效的方式。",
    },
    bankingFinance: {
      content1:
        "我們提供廣泛的銀行和金融法律服務。 我們為能夠為處理融資和擔保事項的貸方和借款人提供動態解決方案和及時服務而感到自豪，以確保交易能夠按時完成。",
      content2:
        "我們的團隊在幾乎所有類型的融資設施（有資金和無資金）以及證券化方面都擁有豐富的專業知識和經驗，經常參與代表客戶進行結構，談判和文檔化的複雜，複雜的融資交易，涉及房地產和房地產等領域。 資產融資，租賃融資，伊斯蘭融資，向一般公司，商業和消費者擔保或無擔保貸款的多貸方銀團貸款。",
      content3:
        "此外，我們的律師還為包括非銀行金融機構（NBFIs）在內的一些領先的本地和國際銀行和金融機構提供了諮詢和協助，以製作和更新其標準模板銀行和金融文件，銀行產品開發和標準化條款以及 條件。 我們的團隊可以從商業角度以及監管方面處理所有銀行和融資事宜，因此我們能夠提供法律上和技術上合理的建議。",
      content4:
        "我們的團隊還精通伊斯蘭融資，為Modarabas和伊斯蘭銀行進行伊斯蘭融資交易（包括結構設計，文件編制和證券化以及開發符合伊斯蘭教義的產品）提供建議。 我們在伊斯蘭金融領域的專長包括以下方面的結構：（a）穆拉巴哈（b）Mushraka /穆沙拉卡減少（c）伊賈拉（Ijara）和（d）Istisna。 我們對符合伊斯蘭教義的銀行業原則的深刻理解，加上我們對當前學者的理解以及對伊斯蘭金融市場實踐的了解，使我們能夠與伊斯蘭金融機構緊密合作，以設計創新和領先的融資結構，並 產品，確保將風險降至最低，並且我們的客戶能夠利用現有機會。",
      content5: "我們協助並建議客戶構建，記錄並最終完成各種融資交易，包括：",
      content6:
        "我們還通過定期的研討會，講習班，情況介紹和內部培訓為客戶提供額外的支持。",
      contentList1: "企業貸款與證券化",
      contentList2: "資產貸款",
      contentList3: "應收賬款融資",
      contentList4: "租賃融資",
      contentList5: "雙邊和銀團貸款",
      contentList6: "伊斯蘭金融",
      contentList7: "一般商業貸款",
      heading1: " 企業融資",
      heading1Content1:
        "我們的律師定期就所有有擔保和無擔保的公司和商業貸款交易，包括但不限於雙邊和辛迪加基於資產的融資，私人企業收購的融資（通過股份購買和資產購買）為藉款人和貸方提供法律意見。 ，應收款融資，過渡性融資和房地產融資，以符合巴基斯坦的法律和法規框架。",
      heading1Content2:
        "憑藉技能，經驗的廣度和地理範圍的結合，我們在準備標準且獨特的貸款文件和擔保結構方面處於優勢地位，無論項目大小，我們都致力於為客戶提供最佳服務。 在我們參與的每筆交易中，我們都致力於有效，及時地完成交易，同時保護雙方的利益。",
      heading2: "  銀行爭議與訴訟",
      heading2Content1:
        "我們的訴訟和爭議解決律師在銀行訴訟和證券執行的各個方面都具有豐富的經驗。 我們向銀行和金融機構以及卡拉奇的信德高等法院提供戰略，經濟高效和無縫的服務，涉及通過執行公司和消費者金融文件及相關證券來收回金融。",
      heading2Content2:
        "此外，我們的律師還為金融機構提供有關政府監管和執法事務（例如FIA，SRB，SBP等），訴訟和交易風險以及爭議解決機制的持續法律顧問。",
      heading3: "   伊斯蘭金融",

      heading3Content1:
        "我們的銀行和金融律師對支持伊斯蘭教法的金融的原則和要求具有專業的了解，並且與領先的伊斯蘭銀行和Modarabas的伊斯蘭教義顧問密切合作，並為複雜的聯合組織伊斯蘭金融交易，投資和產品的結構設計提供了諮詢。",
      heading3Content2: "我們在伊斯蘭金融方面的專業知識包括但不限於以下方面：",
      heading3contentlist1: "回教徒",
      heading3contentlist2: "村巴哈",
      heading3contentlist3: "穆沙拉卡，包括減少穆沙拉卡",
      heading3contentlist4: "伊賈拉",
      heading3contentlist5: "伊斯蒂斯納",
    },
    commerical: {
      content1:
        "為了使企業能夠利用現有機會並確保平穩運行，它們需要明確且強有力的商業協議。 精心起草的商業合同可減輕爭端的風險，並全面規定了當事方的義務，從而確保了業務關係的確定性。",
      content2:
        "作為其商業慣例的一部分，Sheikh & Qazi在各個行業提供廣泛的法律服務。 我們的本地和國際律師在為國家和國際商業客戶提供建議方面具有豐富的經驗，能夠提供清晰智能的商業合同，同時就此類合同的最佳做法提供指導，以確保我們的客戶能夠實現他們的目標。 戰略，商業和商業目標。",
      content3: "我們可以處理幾乎所有描述的商業合同，包括但不限於：",
      contentList1: "談判和起草商業合同",
      contentList2: "跨境合資，夥伴關係和合作協議",
      contentList3: "顧問協議",
      contentList4: "IT協議",
      contentList5: "公司協議（股東協議等）",
      contentList6: "供應和分銷協議",
      contentList7: "特許經營協議",
      contentList8: "服務協議",
      contentList9: "買賣協議",
      contentList10: "標準業務條款和條件",
      content4:
        "我們的律師了解通過風險管理和利用機會來增強客戶業務的價值，並因此利用有用的業務洞察力精心解決所有商業事務。",
    },
    propertyRealState: {
      content1:
        "在財產法的各個領域都具有豐富的經驗和專業知識，無論您是要為複雜的建築項目提供服務的大型組織，還是想審查財產作為融資工具抵押的銀行公司，還是尋求完成住宅的私人客戶 出售或購買，我們可以提供有關財產事務的全方位交易，諮詢和爭議解決法律服務。",
      content2: "我們在物權法方面的專業領域包括：",
      contentList1: "買賣住宅和商業物業",
      contentList2:
        "通過對房地產融資設施和抵押品進行勤勉的財產審查，為銀行和金融機構提供建議",
      contentList3: "房東和房客事務的各個方面",
      contentList4: "財產糾紛解決",
      contentList5: "借款人違約時協助貸款人追償",
    },
    INTELLECTUALPROPERTY: {
      content1:
        "我們在知識產權法的商標領域提供廣泛的服務。 我們為從啟動企業到成熟組織的所有類型的客戶提供有關如何保護，維護和執行其商標和品牌標識的建議。",
      content2: "我們為所有有爭議和無爭議的事項提供建議，包括：",
      contentList1: "商標搜索和商標註冊申請",
      contentList2: "商標許可，特許經營，轉讓和分配協議",
      contentList3: "註冊和未註冊商標權，以及",
      contentList4: "協助客戶進行商標異議和註銷行動",
    },
    privateClientServices: {
      content1:
        "我們為個人提供全面且具有成本效益的法律服務和建議，從企業家和地主到高淨值個人（HNWI）。 我們的律師為您的以下問題提供戰略，高質量和實用的法律解決方案：",
      contentList1: "繼承和繼承很重要",
      contentList2: "個人移民英國（工作，學習，投資，家庭）",
      contentList3: "住宅/商業物業的購置和轉讓",
      contentList4: "家規",
      contentList5: "商業事務和交易",
      heading1: " 繼承與遺產管理",
      heading1Content1:
        "我們為高淨值人士提供廣泛的技術專業知識，涉及繼承和繼承事務，涉及已故家庭成員留下的本地和跨境遺產所引起的複雜問題。 我們對死者的資產和財產位於不同司法管轄區（國家）的情況下產生的法律衝突問題具有豐富的專業知識，並且能夠通過有效而富有同情心的建議幫助您解決複雜性問題。",
    },
    employmentLabour: {
      content1:
        "我們為雇主和個人就各種就業和勞動法事務提供專業，商業和務實的建議。 我們與人力資源和高級管理團隊緊密合作，旨在使僱用事宜變得直截了當，從而使我們的客戶能夠自信地做出決策。",
      content2: "通過我們的就業和勞工實踐小組提供的各種服務是：",
      content3:
        "除了提供優質，實用的法律服務外，我們還為人力資源和管理團隊提供定制培訓和研討會。",
      contentList1: "起草就業合同，政策，手冊和程序",
      contentList2: "關於員工關係問題的日常建議，包括紀律問題，解僱和申訴",
      contentList3: "業務重組，轉移和裁員",
      contentList4: "董事和高級管理人員合同談判和起草",
      contentList5:
        "就不正當解僱，限制貿易和其他僱傭糾紛向就業法庭和高等法院提供諮詢和代理",
      contentList6: "EOBI，SESSI和其他成文法合規和爭議解決",
    },
    companySecretarial: {
      content1:
        "為了確保公司遵守管理公司的法定框架，並避免由於不遵守規定而導致的處罰和監管問題，我們為本地和外國公司提供一系列公司秘書和行政服務，包括：",
      contentList1: "就公司法律和合規性的各個方面提供諮詢",
      contentList2: "公司成立",
      contentList3: "法定表格和決議的準備和歸檔 人員的任命和辭職，年度申報表等",
      contentList4: "確保遵守法定期限和程序",
      contentList5: "處理股份轉讓和發行股票證書",
      contentList6: "如果需要，準備決議和董事會會議記錄",
      contentList7: "維護和更新公司的登記冊和法定記錄",
      contentList8: "擔任代理人和代理人股東",
      contentList9: "隨時通知您重要的合規期限",
      content2: "該服務由受過訓練的專業員工在公司團隊律師的監督下提供。",
      heading1: "公司成立",
      heading1Content1:
        "本地和外國公司/發起人定期指導我們為公司/外國公司的成立提供協助。 因此，我們提供有關以下方面的端到端服務：",
      heading1List1: "民營企業;",
      heading1List2: "上市公司; 和",
      heading1List3: "非營利性公司（《 2017年公司法》第42條）。",
      heading1Content2:
        "我們還根據《 2013年貿易組織法》為客戶提供與貿易組織總局（DGTO）建立貿易組織的建議。",
      heading1Content3:
        "我們的公司註冊方法論方法意味著我們與客戶緊密合作，了解他們希望經營的業務和領域，然後提供戰略指導並採取適用的程序，以確保有效，及時地完成註冊程序。",
    },
    corporate: {
      content1:
        "我們的團隊在商業和公司法事務方面擁有豐富的專業知識，可以為客戶提供充滿活力，動態且務實的解決方案，以解決涉及多個行業領域的複雜交易和公司融資事務。 我們的律師為從初創企業到跨國公司，投資者/股東到家族企業的多元化客戶群提供諮詢和代理服務，提供市場領先的知識並促進客戶的公司交易和融資需求。",
      content2:
        "我們了解客戶的業務目標和優先事項，因此，通過我們的“一站式”方法，我們能夠跨法律學科合作，以具有成本效益的方式為他們的組織提供最佳解決方案。",
      content3:
        "憑藉我們的專業法律技能和強大的業務敏銳度，以及我們在英國相關辦事處的國際律師網絡提供國際視野和支持，我們在一系列公司事務上提供出色的戰略諮詢和法律顧問，包括：",
      contentList1: "談判和交易前協議",
      contentList2: "併購",
      contentList3: "合法盡職調查（買方或賣方）",
      contentList4: "私募股權和風險投資交易",
      contentList5: "合資企業和戰略聯盟",
      contentList6: "合規性",
      contentList7: "股東協議，合資交易",
      contentList8: "清盤和清算",
      contentList9: "公司治理",
      content4:
        "我們根據管理公司的法律和法規框架，定期就管理和運營公司的日常事務向企業家，初創企業，領先的私營和家族企業以及高淨值個人及其業務提供建議，從而為他們提供幫助 瀏覽複雜的行業特定問題。",
      heading1: "企業諮詢與談判",
      heading1Content1:
        "我們的公司律師以其法律技能和勤於處理複雜公司事務的能力而聞名。 由於我們的律師對客戶的業務需求採取周到和積極的態度，因此我們定期與多樣化的客戶群合作，從初創企業到跨國公司，從家族企業到非營利組織，我們都尋求律師的幫助。 處理一系列公司法律事務，並代表客戶在另一方就擬議的交易和交易文件進行談判以取得最佳結果之前。",
      heading1Content2:
        "我們的企業諮詢基於豐富的本地和國際經驗，涵蓋了廣泛的領域，包括：",
      heading1ContentList1: "籌集資金（通過股權和/或債務）",
      heading1ContentList2: "股東協議和爭議解決",
      heading1ContentList3: "董事的職責和責任",
      heading1ContentList4: "SECP和其他監管合規",
      heading1ContentList5: "公司治理問題和合規性",
      heading1ContentList6: "董事會政策和董事會糾紛",
      heading1ContentList7: "首席官服務合同",
      heading1Content3:
        "我們以客戶為中心的方法對我們的專業法律專業知識進行補充，以確保出色的客戶服務和價值。 此外，我們建立了一套培訓計劃和研討會，以使我們的客戶掌握最新的監管制度動態，並增進客戶對基本商業法原則的理解。",
      heading2: "家族企業",
      heading2Content1:
        "我們的團隊匯集了各種法律學科的技能，非常熟練地為家族企業和受控制的企業提供涵蓋各個關鍵行業的全面法律服務。 我們的多學科和細緻入微的方法可確保我們在提供清晰，智能和務實的建議來管理和保護您的資產並支持您的企業和家庭的長遠未來之前，對您的業務和您的家庭目標有深刻的了解。",
      heading2Content2:
        "我們為各個領域的各種規模的家族企業提供的專業知識和服務包括：",
      heading2Content3: "我們與客戶互動，以便及時制定策略並定期審查計劃。",
      heading2ContentList1: "業務繼任計劃",
      heading2ContentList2: "家庭和企業治理協議",
      heading2ContentList3: "業務結構/重組",
      heading2ContentList4: "股東和憲法安排",
      heading2ContentList5: "家庭糾紛管理與解決",
      heading2ContentList6: "符合伊斯蘭教義的遺囑和繼承計劃",
      heading2Content4: "我們與客戶互動，以便及時制定策略並定期審查計劃。",
      heading3: "併購",
      heading3Content1:
        "我們以結果為導向的團隊致力於使交易快速，流暢和具有成本效益。 我們在併購業務領域的交易重點主要是涉及復雜結構和獨特考慮因素的私人公司併購，儘管我們也定期就業務重組和重組提供建議。 我們的目標是在整個交易的每個階段（從盡職調查，結構化和談判直至執行和結束）提供非凡的價值，並提供無縫服務以實現客戶的商業目標。 我們在這方面的服務包括但不限於：",
      heading3contentlist1: "交易談判；",
      heading3contentlist2: "意向書/條款負責人；",
      heading3contentlist3: "盡職調查;",
      heading3contentlist4: "關鍵文件的準備；",
      heading3contentlist5: "披露；",
      heading3contentlist6: "合規性； 和",
      heading3contentlist7: "完成後的手續。",
      heading3Content2:
        "我們的公司律師在其他業務領域的大力支持下以及專業顧問網絡的協助下，也定期就公司實體與機構投資者之間的合資安排的成立和結構提供法律意見，包括特殊目的實體的形成，資金，商業條款，稅收影響，終止 和爭議管理。",
      heading4: "  初創公司和新興公司",
      heading4Content1:
        "我們代表不同行業的初創公司和早期公司，並為這些公司提供建議，並與這些公司從成立到發展，成長和擴展階段合作。 我們與這些新興公司緊密合作，為您提供廣泛的諮詢服務，包括選擇業務結構，協助其融資需求，債務或股權以及隨後的退出策略和投資實現。 無論是初創公司還是早期公司的需求都是什麼，我們都有能力和實踐經驗來提供對其成功至關重要的法律服務。",
    },
    disputeResolution: {
      content1:
        "Sheikh & Qazi的訴訟和爭議解決專家可以在廣泛的法律領域中幫助您解決複雜而壓力大的爭議性問題。我們一直致力於為客戶提供最高質量的法律代表和訴訟。我們與客戶緊密合作，在爭端開始時評估他們的需求和目標，然後就案情提供切實可行的建議，並探索解決方案的所有可用方案，包括為客戶提供信息談判和調解服務。",
      content2:
        "在無法進行庭外和解的情況下，我們是頑強而有效的訴訟人，在處理具有挑戰性的訴訟方面經驗豐富，採用了多方面的方法。我們自豪地宣稱我們的客戶服務標準，建議質量和交付方式與我們在其他業務領域中的服務均處於同等水平。這意味著我們反應迅速，平易近人，並迅速採取行動以提供清晰的建議，從而確保我們的客戶始終能夠及時了解其狀況和進展。此外，借助我們創新的訴訟管理工具，我們能夠提高效率，並在所有情況下都能及時提供溝通和函電。",
      content3:
        "我們向卡拉奇地區法院，卡拉奇信德高等法院以及在以下地區的準司法論壇和法庭出庭提供法律代理和訴訟服務：",
      contentList1: "銀行糾紛和財務追回",
      contentList2: "民事糾紛",
      contentList3: "商業和合同糾紛",
      contentList4: "憲法和法定權利執行",
      contentList5: "誹謗和聲譽管理",
      contentList6: "僱傭糾紛",
      contentList7: "知識產權糾紛",
      contentList8: "財產和房地產糾紛",
      content4:
        "我們的客戶群包括上市和私有公司，銀行和金融機構，高資產淨值人士，家族企業，非營利組織以及外籍和海外巴基斯坦國民。",
      content5:
        "通過我們在英國關聯辦公室的訴訟專家，我們還能夠有效地在英國處理跨境訴訟。",
      heading1: "銀行爭議與訴訟",
      heading1Content1:
        "我們在各種有爭議的事務中代表銀行和金融機構，但主要是根據融資和安全文件為消費者和公司客戶所使用的財務進行追償。我們的方法迅速有效，與銀行內部法律顧問緊密合作，確保充分減輕任何外部風險。",
      heading2: "民事糾紛與訴訟",
      heading2Content1:
        "我們為企業和私人客戶因其公民權利引起的糾紛提供法律服務，尋求索賠，特定性能或禁制令。民事權利可能在各個領域產生，包括房東和房客糾紛，家庭法糾紛，金錢索償，誹謗，合夥糾紛等。我們採取以結果為導向的方法，並與客戶討論索償的優劣，然後再承擔代表他們。",
      heading2Content2:
        "訂婚後，我們將採取敏捷有效的法律策略，以確保為客戶帶來最有利的結果。必要時，我們可以迅速採取行動以取得禁制令，以防止將來對客戶造成任何損害。我們事務所的一大特色是，我們通過共享案件更新，即將舉行的聽證會和所有必要的信息，使客戶經常了解案件的狀況，從而使我們的客戶放心。",
      heading3: "商業和合同糾紛",
      heading3Content1:
        "我們了解合同和商業糾紛可能對我們的企業和私人客戶均造成損害，因此需要謹慎處理和緊急響應。我們的訴訟律師團隊經驗豐富，以勤奮和響應的方式處理商業和合同糾紛。",
      heading3Content2:
        "我們在卡拉奇高等法院和地方法院為客戶提供法律服務，涉及因違反合同和商業交易，股東糾紛，專業疏忽，違反監管規定，違反僱傭合同，侵犯知識產權等引起的糾紛。發生爭議時，我們可以幫助客戶在整個訴訟案件的整個生命週期中無縫導航，並確保在此問題的每個階段都為我們的客戶提供最新信息。",
      heading4: "憲法權利與訴訟",
      heading4Content1:
        "根據1973年《巴基斯坦伊斯蘭共和國憲法》的規定，我們為客戶處理法律，商業和憲法因素的複雜混合，圍繞公平和正義問題。他們可以採取的補救措施，並能夠根據《憲法》第199條的適用規定，迅速向高等法院提出訴狀/憲法訴請。",
      heading4Content2:
        "此外，我們還通過我們的公益訴訟司，在與保護或維護公眾權利有關的問題上，援引高等法院的憲法管轄權。",
      heading5: "財產與房地產糾紛",
      heading5Content1:
        "我們的訴訟團隊精通代表房地產和房地產相關交易引起爭議的商業和私人客戶。我們為該領域的各種事務提供建議，包括商業和住宅業主和租戶糾紛，合同解釋（買賣協議，買賣契據），規劃和建築糾紛等。我們的訴訟人平易近人，具有說服力，並且具有出色的辯護技巧和致力於為我們的客戶實現最佳結果。 ",
    },
    familyLaw: {
      content1:
        "在 S&Q，我们热情而细致的团队为客户提供最重要的待遇，就他们的争议提供最佳建议，就像让他们感到舒适并确保我们为他们提出的补救措施最符合他们的利益。因此，地区一级的任何家庭案件都被视为最高法院的案件。无论是赡养纠纷、离婚纠纷还是监护权问题，都可以依靠 S&Q 来理解并解决问题。",
      content2: "以下是我们在巴基斯坦家庭法院的主要业务领域：        ",
      heading1: "通过离婚/呼啦舞解除婚姻：",
      heading1Content1:
        "婚姻的结束总是一大步。但如果做得正确，它可能是苦乐参半的。我们真的相信在解除婚姻时向我们的客户提供正确的建议，因为在此步骤之后必须充分了解同样的后果。",
      heading1Content2:
        "S&Q 相信在处理离婚/khula 案件时提供最敏感的法律服务和客户处理。值得一提的是，khula 并不是女性解除婚姻的唯一选择。由于穆斯林家庭法条例规定了客户可以通过法院寻求离婚的 8 个理由，而 khula 是其中之一，而不仅仅是大多数客户不知道的。我们的法律顾问确保为您提供最符合您利益的最佳选择",
      heading2: "维护：",
      heading2Content1:
        "赡养费是最复杂的争议之一，无论是证明指控还是拒绝指控，都需要有力的证据。我们 S&Q 是在法庭上为您积极争取的倡导者，以便为您提供最好的服务",
      heading2Content2:
        "这是我们的律师有惊人的指挥权为您辩护的实践领域之一，无论是从妻子身边还是从丈夫出现。我们始终代表我们的客户福利。",
      heading3: "监护权/监护权：",
      heading3Content1:
        "对我们来说，您的孩子比任何财产都更有价值。我们为您的合法权利而战，将您的利益置于任何其他承诺之上。我们竭尽全力确保您的孩子获得正确的法律追索权，这将有利于他/她的长期未来。我们的专业家庭律师确保为我们的客户和未成年人争取最佳的赡养费。",
      heading3Content2:
        "这是我们的律师有惊人的指挥权为您辩护的实践领域之一，无论是从妻子身边还是从丈夫出现。我们始终代表我们的客户福利。",
      heading3Content3:
        "不仅为母亲们，我们还代表父亲们在他们有权利的情况下进行激烈的辩论，以确保他对任何本质上不真实的指控伸张正义。",
      heading3Content4: "可以在监护权问题上向法院提出的争议有：",
      contentList1: "会议权利",
      contentList2: "保管",
      contentList3: "保护",
      contentList4: "临时监护",
      heading4: "家庭暴力:",
      heading4Content1:
        "配偶、父母、孩子或兄弟姐妹的家庭暴力正变得越来越普遍。家庭暴力包括身体、心理、性、语言和经济上的不当暴力行为。虽然在更严重的情况下可以将此区域视为刑事案件，但也可以将此事提交给家庭法官。鉴于巴基斯坦有针对家庭虐待的特殊立法，我们会在征收这一点的同时明确地为您的案件辩护，以便您获得最好的补救措施。这可以采取损害赔偿、临时和限制令、khulas/离婚和获得飞行情报区的形式。不幸的是，客户缺乏同样的意识，因此有很多人闻所未闻。我们确保通过将暴力肇事者告上法庭来传播意识并阻止暴力肇事者。",
    },
    taxation: {
      content1:
        "Sheikh and Qazi Advocates, Corporate and Tax Consultants 由一支具有高度法律敏锐度的专业律师团队监督和管理，并在直接和间接税收方面拥有一流的技能。作为税务顾问，我们协助我们尊敬的客户进行税务规划，以在法律参数范围内提高税务效率，此外，我们还就税务影响的战略提供规划和建议。",
      content2:
        "我们相信与我们尊敬的客户建立密切的关系，这使我们能够了解业务流程，这对于推荐合适的节税业务模式至关重要。我们为员工提供吸引、培养和挑战最有才华的专业人士的工作环境。我们处理从个人到跨国组织的各种类型和规模的事务。",
      content3: "S&Q 提供的服务可分为以下几个部分",
      heading1: "直接税务服务:",
      heading2: "所得税咨询服务",
      heading2contentList1: "就所得税事宜提供书面和口头建议和意见",
      heading2contentList2: "适用于公司、AOP 和个人的新税法的建议和更新",
      heading3: "所得税合规服务",
      heading3contentList1: "在给定的时间范围内按月以电子方式提交预扣税报表",
      heading3contentList2: "按季度计算预缴所得税的建议及对部门的相关提示",
      heading3contentList3: "计算资本利得税的建议及对部门的相关暗示",
      heading3contentList4: "编制公司所得税规定        ",
      heading3contentList5: "计算应纳税人的总收入及其税金",
      heading3contentList6: "公司年度所得税申报表的准备和归档，AOP",
      heading3contentList7:
        "根据 2001 年所得税条例，遵守与预扣税审计程序有关的法律通知",
      heading3contentList8: "遵守与所得税修订程序有关的法律通知        ",
      heading3contentList9: "准备个人所得税申报表      ",
      heading4: "间接税服务（联邦和省税）：",
      heading4contentList1: "营业税申报审查和咨询服务      ",
      heading4contentList2: "        遵守有关销售税申报表及其陈述的法律声明",
      heading4contentList3: "省级申报和咨询服务      ",
      heading4contentList4: "每月销售税申报表的合规性      ",
      heading4contentList5:
        "向税务局局长（上诉）和/或上诉法庭和/或更高当局提出申述",
      heading4contentList6: "准备和跟进退款案件",
      heading4contentList7: "代表销售税审计程序",
      heading5:"间接税收服务"
    },
    OurPros: {
      content:
        "在Sheikh & Qazi，我們的客戶範圍從跨國公司到獨資公司。 無論規模和部門如何，我們都會像對待最重要的客戶一樣對待每一個客戶，提供針對您的業務和您面臨的獨特問題的量身定制的戰略法律顧問。",
    },
    aboutData: {
      heading1: "關於賽義德與謝赫",
      heading2: "我們的專長",
      heading3: "繼續...",
      content1:
        "我們為雇主和個人就各種就業和勞動法事務提供專業，商業和務實的建議。.",
      content2: "聯繫我們.",
      content3: "版權所有Sheikh & Qazi。 版權所有",
      content4: "開發者",
      content5: "電話",
      content6: "電子郵件",
      content7: "   移動的",
      content8: "  我們的地址",
      content9: "   定時",
      content10: "   給我們打電話",
      contentSchedule1: "   星期一到星期五",
      contentSchedule2: "  週六",
    },
    careers: {
      content1:
        "Sheikh & Qazi的工作場所專業，以團隊為中心並且是合議的，在這裡，各種才能和技能都受到高度重視。 在Sheikh & Qazi，您將成為我們法律團隊的一員，並被鼓勵為複雜的客戶針對複雜的法律事務進行創新，制定戰略並進行合作。",
    },
    
    npo:{
      heading1: "NPO 和 NGO 注册",
      content1:
      "S&Q 为巴基斯坦伊斯兰共和国的非营利组织和协会提供全面的法律服务。 我们为寻求实现非营利目标的企业、机构、教育、社会、宗教、个人和家庭提供服务。 在巴基斯坦，有许多用于注册非营利组织的法律，包括著名的法令和条例，如 1860 年社团注册法、1882 年信托法、2017 年公司法第 42 条下的非营利公司注册、 1925 年合作社法、2007 年贸易组织条例、2003 年非营利公益组织（治理和支持）法、1923 年穆斯林宗教基金法、1923 年慈善捐赠法。",
      content2:"S&Q 为巴基斯坦的非营利组织提供广泛的法律服务，包括：",
      heading1contentList1:   "非营利组织/非政府组织的注册",
      heading1contentList2:   "准备文件",
      heading1contentList3:   "就其职责和责任向非营利组织董事会成员提供建议",
      heading1contentList4:   "就慈善捐赠事宜向个人和实体提供建议",
      heading1contentList5:   "为非营利组织获得免税提供建议",
      heading1contentList6:   "在调解、仲裁、诉讼和类似事务中代表和辩护所有类型的非营利组织",
      heading1contentList7:   "就房地产交易、环境等问题提供咨询",
      heading1contentList8:   "为非政府组织的筹款工作提供建议",
      heading1contentList9:   "就知识产权问题提供咨询，申请和保护版权或商标，并获得使用版权材料的许可",
      heading1contentList10:  "起草雇佣协议",
      heading1contentList11:  "解散 NPO/NGO",
      content3:"联系我们获取关于哪种类型的 NGO/NPO 最适合您的需求的建议。",
    }
  },
};

export default translations;
