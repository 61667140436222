import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";

var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            {/* <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1> {I18n.t("home.servicesubHeading2").toUpperCase()}</h1>
              </div>
            </section> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>{I18n.t("home.servicesubHeading2").toUpperCase()}</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="blog" class="blog">
              <div class="container" data-aos="fade-up">
                <div class="row">
                  <div class="col-lg-12 entries">
                    <article class="entry">
                      <div class="entry-content">
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                          {I18n.t("companySecretarial.content1")}
                        </p>
                        <br />
                        <ul style={{ listStyleType: "none" }}>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList1")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList2")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList3")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList4")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList5")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList6")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList7")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList8")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("companySecretarial.contentList9")}
                          </li>
                        </ul>
                        <br />
                        <p> {I18n.t("companySecretarial.content2")}</p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </section>

            <section id="clients" class="clients">
              <div class="container">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {I18n.t("companySecretarial.heading1")}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                          {I18n.t("companySecretarial.heading1Content1")}
                        </p>
                        <br />
                        <ul style={{ listStyleType: "none" }}>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0a4d8c",
                              }}
                            >
                              {" "}
                            </i>
                            {I18n.t("companySecretarial.heading1List1")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0a4d8c",
                              }}
                            >
                              {" "}
                            </i>
                            {I18n.t("companySecretarial.heading1List2")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0a4d8c",
                              }}
                            >
                              {" "}
                            </i>
                            {I18n.t("companySecretarial.heading1List3")}
                          </li>
                        </ul>

                        <p style={{ textAlign: "justify" }}>
                          {I18n.t("companySecretarial.heading1Content2")}
                        </p>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                          {I18n.t("companySecretarial.heading1Content3")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {I18n.t("bankingFinance.heading2")}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                          {I18n.t("bankingFinance.heading2Content1")}
                        </p>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                          {I18n.t("bankingFinance.heading2Content2")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        {I18n.t("bankingFinance.heading3")}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                          {I18n.t("bankingFinance.heading3Content1")}
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          {I18n.t("bankingFinance.heading3Content2")}
                        </p>
                        <div class="content">
                          <ul style={{ listStyle: "none" }}>
                            <li style={{ fontSize: "16px" }}>
                              <i
                                class="icofont-check-circled"
                                style={{
                                  fontSize: "16px",
                                  paddingRight: "4px",
                                  color: " #0a4d8c",
                                }}
                              >
                                {" "}
                              </i>
                              {I18n.t("bankingFinance.heading3contentlist1")}
                            </li>
                            <li style={{ fontSize: "16px" }}>
                              <i
                                class="icofont-check-circled"
                                style={{
                                  fontSize: "16px",
                                  paddingRight: "4px",
                                  color: " #0a4d8c",
                                }}
                              >
                                {" "}
                              </i>
                              {I18n.t("bankingFinance.heading3contentlist2")}
                            </li>
                            <li style={{ fontSize: "16px" }}>
                              <i
                                class="icofont-check-circled"
                                style={{
                                  fontSize: "16px",
                                  paddingRight: "4px",
                                  color: " #0a4d8c",
                                }}
                              >
                                {" "}
                              </i>
                              {I18n.t("bankingFinance.heading3contentlist3")}
                            </li>
                            <li style={{ fontSize: "16px" }}>
                              <i
                                class="icofont-check-circled"
                                style={{
                                  fontSize: "16px",
                                  paddingRight: "4px",
                                  color: " #0a4d8c",
                                }}
                              >
                                {" "}
                              </i>
                              {I18n.t("bankingFinance.heading3contentlist4")}
                            </li>
                            <li style={{ fontSize: "16px" }}>
                              <i
                                class="icofont-check-circled"
                                style={{
                                  fontSize: "16px",
                                  paddingRight: "4px",
                                  color: " #0a4d8c",
                                }}
                              >
                                {" "}
                              </i>
                              {I18n.t("bankingFinance.heading3contentlist5")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
