import React from "react";
import logo from "../assets/userLogo.png";
import pix4 from "../assets/pix4.jpg";
import pix5 from "../assets/pix5.jpg";
import pix3 from "../assets/pix3.jpg";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
var I18n = require("react-redux-i18n").I18n;
var name = I18n.t("home.title");
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      items: [
        {
          src: pix4,
          altText: "Slide 1",
          caption: "Slide 1",
          name: I18n.t("home.title"),
          mainHeading: I18n.t("home.mainHeading"),
          readMore: I18n.t("home.readMore"),
        },
        {
          src: pix5,
          altText: "Slide 2",
          caption: "Slide 2",
          name: I18n.t("home.title"),
          mainHeading: I18n.t("home.mainHeading"),
          readMore: I18n.t("home.readMore"),
        },
        // {
        //   src: pix3,
        //   altText: "Slide 3",
        //   name: I18n.t("home.title"),
        //   mainHeading: I18n.t("home.mainHeading"),
        //   readMore: I18n.t("home.readMore"),
        // },
      ],
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }
  componentDidMount() {
    // const carousel = new bootstrap.Carousel("#myCarousel");
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    const { items } = this.state;
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    const { items } = this.state;
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  render() {
    const { activeIndex, items } = this.state;

    return (
      <section id="hero">
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          //   slide={false}
          // interval={1000}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {items.map((item) => {
            //   console.log(item);
            return (
              <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={item.src}
              >
                <div
                  // class="carousel-item "
                  style={{
                    background: `url(${item.src})`,
                    height: "100vh",
                    width: "100vw",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></div>

                <div class="hero-container aos-init aos-animate">
                  <h1
                    class="marTop"
                    //    style={{marginBottom: '5rem'}}
                  >
                    {item.name.toUpperCase()}
                  </h1>
                  <p
                    class="lead"
                    style={{
                      textAlign: "justify",
                      //   marginRight: "20rem",
                      //   marginLeft: "20rem",
                      color: "white",
                    }}
                  >
                    {item.mainHeading}
                  </p>
                  <a href="#about" class="btn-get-started scrollto">
                    {item.readMore}
                  </a>
                </div>
              </CarouselItem>
            );
          })}

          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </section>
    );
  }
}

export default Home;

// <div
// id="carouselExampleControls"
// class="carousel slide"
// data-bs-ride="carousel"
// >
// <div class="carousel-inner">
//   <div class="carousel-item active">
//     <div
//       // class="carousel-item "
//       style={{
//         background: `url(${pix5})`,
//         height: "100vh",
//         width: "100vw",
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center",
//       }}
//     ></div>
//     <div class="hero-container aos-init aos-animate">
//       <div class="d-flex justify-content-center">
//         <img
//           class="animate__animated animate__fadeInDown img-thumbnail"
//           src={logo}
//           // class="img-fluid"
//           // class="d-block w-100"
//           alt="..."
//           style={{
//             // backgroundRepeat: "no-repeat",
//             // height: "500px",
//             width: "500px",
//             backgroundPosition: "center",
//             backgroundSize: "contain",
//           }}
//           // width={50}
//         />
//       </div>
//     </div>
//   </div>
//   <div class="carousel-item">
//     <div
//       // class="carousel-item "
//       style={{
//         background: `url(${pix5})`,
//         height: "100vh",
//         width: "100vw",
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center",
//       }}
//     ></div>
//     <div class="hero-container aos-init aos-animate">
//       <h1
//         class="marTop"
//         //    style={{marginBottom: '5rem'}}
//       >
//         {" "}
//         {I18n.t("home.title")}
//       </h1>
//       <p
//         class="lead"
//         style={{
//           //   marginRight: "20rem",
//           //   marginLeft: "20rem",
//           color: "white",
//         }}
//       >
//         {I18n.t("home.mainHeading")}
//       </p>
//       <a href="#about" class="btn-get-started scrollto">
//         {I18n.t("home.readMore")}
//       </a>
//     </div>
//   </div>
//   <div class="carousel-item">
//     <div
//       // class="carousel-item "
//       style={{
//         background: `url(${pix4})`,
//         height: "100vh",
//         width: "100vw",
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center",
//       }}
//     ></div>
//     <div class="hero-container aos-init aos-animate">
//       <h1
//         class="marTop"
//         //    style={{marginBottom: '5rem'}}
//       >
//         {" "}
//         {I18n.t("home.title")}
//       </h1>
//       <p
//         class="lead"
//         style={{
//           //   marginRight: "20rem",
//           //   marginLeft: "20rem",
//           color: "white",
//         }}
//       >
//         {I18n.t("home.mainHeading")}
//       </p>
//       <a href="#about" class="btn-get-started scrollto">
//         {I18n.t("home.readMore")}
//       </a>
//     </div>
//   </div>
// </div>
// <button
//   class="carousel-control-prev"
//   type="button"
//   data-bs-target="#carouselExampleControls"
//   data-bs-slide="prev"
// >
//   <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//   <span class="visually-hidden">Previous</span>
// </button>
// <button
//   class="carousel-control-next"
//   type="button"
//   data-bs-target="#carouselExampleControls"
//   data-bs-slide="next"
// >
//   <span class="carousel-control-next-icon" aria-hidden="true"></span>
//   <span class="visually-hidden">Next</span>
// </button>
// </div>
