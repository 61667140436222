import React from "react";
import logo from "../assets/imgHome/blog-recent-4.jpg";
var I18n = require("react-redux-i18n").I18n;
const About = () => {
  return (
    <section id="about" class="about">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-lg-11">
            <div class="row justify-content-end">
              {/* <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box py-5">
                  <i class="bi bi-emoji-smile"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="65"
                    class="purecounter"
                  >
                    0
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box py-5">
                  <i class="bi bi-journal-richtext"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="85"
                    class="purecounter"
                  >
                    0
                  </span>
                  <p>Projects</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box pb-5 pt-0 pt-lg-5">
                  <i class="bi bi-clock"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="27"
                    class="purecounter"
                  >
                    0
                  </span>
                  <p>Years of experience</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box pb-5 pt-0 pt-lg-5">
                  <i class="bi bi-award"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="22"
                    class="purecounter"
                  >
                    0
                  </span>
                  <p>Awards</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-lg-6 video-box align-self-baseline position-relative">
            {/* <img src="assets/img/about.jpg" class="img-fluid" alt="" /> */}
            <img src={logo} class="img-fluid" alt="" />
            {/* <a
              href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
              class="glightbox play-btn mb-4"
            ></a> */}
          </div>

          <div class="col-lg-6 pt-3 pt-lg-0 content">
            <h3>
              {/* Voluptatem dignissimos provident quasi corporis voluptates sit
              assumenda. */}
              {I18n.t("home.aboutSectionLegal")}
            </h3>    <br />
            <p style={{textAlign: "justify"}}>{I18n.t("home.aboutSectionLegal1")}</p>
            <br />
            <p style={{textAlign: "justify"}}>{I18n.t("home.aboutSectionLegal2")}</p>
            <br />
            <p style={{textAlign: "justify"}}>{I18n.t("home.aboutSectionLegal3")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
