import React, { Component } from "react";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
// import "./styles.css";
var I18n = require("react-redux-i18n").I18n;
export default class taxation extends Component {
  constructor() {
    super();
    this.state = {
      arr1: [
        { id: 1, list: "taxation.heading3contentList1" },
        { id: 2, list: "taxation.heading3contentList2" },
        { id: 3, list: "taxation.heading3contentList3" },
        { id: 4, list: "taxation.heading3contentList4" },
        { id: 5, list: "taxation.heading3contentList5" },
        { id: 6, list: "taxation.heading3contentList6" },
        { id: 7, list: "taxation.heading3contentList7" },
        { id: 8, list: "taxation.heading3contentList8" },
        { id: 9, list: "taxation.heading3contentList9" },
      ],
      arr2: [
        { id: 1, list: "taxation.heading4contentList1" },
        { id: 2, list: "taxation.heading4contentList2" },
        { id: 3, list: "taxation.heading4contentList3" },
        { id: 4, list: "taxation.heading4contentList4" },
        { id: 5, list: "taxation.heading4contentList5" },
        { id: 6, list: "taxation.heading4contentList6" },
        { id: 7, list: "taxation.heading4contentList7" },
      ],
    };
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>{I18n.t("home.servicesubHeading11").toUpperCase()}</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}
            {/* <!-- ======= About Section ======= --> */}
            <section id="blog" class="blog">
              <div class="container" data-aos="fade-up">
                <div class="row">
                  <div class="col-lg-12 entries">
                    <article class="entry">
                      <div class="entry-content">
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                          {I18n.t("taxation.content1")}
                        </p>
                        <br />
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                          {I18n.t("taxation.content2")}
                        </p>
                        <br />
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                          {I18n.t("taxation.content3")}
                        </p>
                      </div>
                      <br />
                    </article>
                  </div>
                </div>
              </div>
            </section>
            <section id="clients" class="clients">
              <div class="container">
                <h2 class="text-center">
                  {I18n.t("taxation.heading1").toUpperCase()}
                </h2>
                <br />

                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {I18n.t("taxation.heading2")}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul style={{ listStyle: "none" }}>
                          <li>
                            {" "}
                            <i class="icofont-check-circled liIcon"></i>{" "}
                            {I18n.t("taxation.heading2contentList1")}
                          </li>
                          <li>
                            {" "}
                            <i class="icofont-check-circled liIcon"></i>{" "}
                            {I18n.t("taxation.heading2contentList2")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        {I18n.t("taxation.heading3")}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul style={{ listStyle: "none" }}>
                          {this.state.arr1.map((e, i) => {
                            return (
                              <li key={e.id}>
                                <i class="icofont-check-circled liIcon"></i>{" "}
                                {I18n.t(e.list)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <h2 class="text-center mt-5">
                    {I18n.t("taxation.heading5").toUpperCase()}
                  </h2>
                  <br />
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        {I18n.t("taxation.heading4")}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                          <ul style={{ listStyle: "none" }}>
                            {this.state.arr2.map((e, i) => {
                              return (
                                <li key={e.id}>
                                  <i class="icofont-check-circled liIcon"></i>{" "}
                                  {I18n.t(e.list)}
                                </li>
                              );
                            })}
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
