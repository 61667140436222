import React, { Component } from "react";
// import {} from "react-router"; // Note this extra line
// import { english_lang, chinese_lang, set_lang } from "../store/actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { remove_user } from "../store/actions/actions";
import { setLocale } from "react-redux-i18n";

/************************* USERS SECTION ******************************/
import logo from "../assets/userLogo.png";
import firebase from "../config/firebase";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  logout = () => {
    const { history } = this.props;
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/Login");
        this.hideModal();
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
        console.log(error.message);
      });
    this.props.logout_user();
  };

  openModal = (event) => {
    document.body.classList.add("mobile-nav-active");
    this.setState({ showModal: true });
  };
  hideModal = (event) => {
    document.body.classList.remove("mobile-nav-active");
    this.setState({ showModal: false });
  };
  render() {
    const { user } = this.props;
    // console.log("user", user.user.user.email);
    return (
      <div>
        <header id="header" class="fixed-top d-flex align-items-center">
          <div class="container d-flex align-items-center justify-content-between">
            <div class="logo">
              <div>
                <a href="/">
                  <img
                    src={logo}
                    alt="userLogo"
                    style={{
                      height: "100px",
                    }}
                  />
                </a>
              </div>
            </div>

            <nav id="navbar" class="navbar">
              <ul>
                <li>
                  <a href="/dashboard">Dashboard</a>
                </li>

                <li>
                  <a href="/users">Users</a>
                </li>
                <li>
                  <a href="/add-careers">Careers</a>
                </li>

                <li class="dropdown">
                  <a href="#">
                    Statutes
                    <i class="bi bi-chevron-down"></i>
                  </a>
                  {/* <i class="bi bi-chevron-down"></i> */}
                  <ul>
                    <li>
                      <a href="/add-statutes">Add Statutes</a>
                    </li>
                    <li>
                      <a href="/categories">Add Catergory</a>
                    </li>
                  </ul>
                </li>
                <li class="dropdown">
                  <a href="#">
                    {" "}
                    {user.user && user.user.user.email}
                    <i class="bi bi-chevron-down"></i>
                  </a>
                  {/* <i class="bi bi-chevron-down"></i> */}
                  <ul>
                    <li>
                      <a href="/account">Account</a>
                    </li>
                    <li>
                      <a href="/setting">Setting</a>
                    </li>
                    <li>
                      {/* <a onClick={() => this.props.chin_lang("ch")}> */}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => this.logout()}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li><a class="getstarted" href="about.html">Get Started</a></li> */}
              </ul>
            </nav>
            <nav
              class={
                this.state.showModal === true
                  ? "navbar navbar-mobile"
                  : "navbar d-lg-none d-md-none d-sm-none d-xs-none"
              }
            >
              <ul>
                <li>
                  <a href="/dashboard">Dashboard</a>
                </li>

                <li>
                  <a href="/users">Users</a>
                </li>
                <li>
                  <a href="/add-careers">Careers</a>
                </li>

                <li class="dropdown">
                  <a
                    onClick={() => this.setState({ dropD: !this.state.dropD })}
                  >
                    Statutes
                    <i class="bi bi-chevron-down"></i>
                  </a>
                  {/* <i class="bi bi-chevron-down"></i> */}

                  <ul class={this.state.dropD ? "dropdown-active" : ""}>
                    <li>
                      <a href="/add-statutes">Add Statutes</a>
                    </li>
                    <li>
                      <a href="/categories">Add Catergory</a>
                    </li>
                  </ul>
                </li>
                <li class="dropdown">
                  <a
                    onClick={() =>
                      this.setState({ dropD2: !this.state.dropD2 })
                    }
                  >
                    {" "}
                    {user.user && user.user.user.email}
                    <i class="bi bi-chevron-down"></i>
                  </a>
                  {/* <i class="bi bi-chevron-down"></i> */}

                  <ul class={this.state.dropD2 ? "dropdown-active" : ""}>
                    <li>
                      <a href="/account">Account</a>
                    </li>
                    <li>
                      {/* <a onClick={() => this.props.chin_lang("ch")}> */}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => this.logout()}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li><a class="getstarted" href="about.html">Get Started</a></li> */}
              </ul>

              <i
                class={
                  this.state.showModal === true
                    ? "bi mobile-nav-toggle bi-x"
                    : "bi mobile-nav-toggle bi-list"
                }
                style={{ color: "#fff !important" }}
                onClick={() =>
                  this.setState({
                    showModal: !this.state.showModal,
                  })
                }
                // onClick={() => console.log('success')}
              ></i>
            </nav>
          </div>
        </header>

        <div
          class="mobile-nav-overly"
          style={{ display: this.state.showModal === false ? "none" : "block" }}
        ></div>
      </div>
    );
  }
}

// export default Navbar;
// export default Todo;
const mapStateToProps = (state) => {
  // console.log(state.reducers);
  return {
    getReduxData: state.data,
    // userR: state.user,
    user: state.reducers,
    // userfb: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // eng_lang: (data) => dispatch(english_lang(data)),
    // chin_lang: (data) => dispatch(chinese_lang(data)),
    logout_user: () => dispatch(remove_user()),
    setLanguage: (language) => dispatch(setLocale(language)),
  };
};
// export default withStyles(styles)(Login);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
