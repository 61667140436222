import React, { Component } from "react";
import { About, CTA, Services, Home } from "../../components/index";

import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import { connect } from "react-redux";
import "../../App.css";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = { heightSet: "" };
    this.myRefHome = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    // this.updateDimensions();

    window.addEventListener("scroll", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ heightSet: window.scrollY });
    // console.log(window.scrollY);
    // console.log("window.pageYOffset", window.pageYOffset);
  }
  handleOnHome = (event) => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  render() {
    const winHeight = window.scrollY;
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <Home />
          <main id="main">
            <About />
            {/* <CTA /> */}
            <Services />
          </main>

          <div>
            <Footer />
          </div>

          <a
            onClick={() => this.handleOnHome()}
            class={
              winHeight > 100
                ? "back-to-top d-flex align-items-center justify-content-center active"
                : "back-to-top d-flex align-items-center justify-content-center"
            }
          >
            <i class="bi bi-arrow-up-short"></i>
          </a>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { language: state.language };
};
// export default Home;
export default connect(mapStateToProps)(Main);
