import React, { Component } from "react";
import Navbar from "../../config/NavAdmin";
import firebase from "../../config/firebase";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import renderHTML from "react-render-html";

import {
  Button,
  Modal,
 
  ModalBody,
  ModalFooter,
  Spinner,
 
} from "reactstrap";
var database = firebase.database();
ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "imageUpload",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      checkedActive: false,
      toggleEdit: false,
      arrData: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
  addUserModal = () => {
    this.setState({
      modal: !this.state.modal,
      jobTitle: "",
      jobPara: "",
      emailLink: "",
      checkedActive: "",
      data: "",
      checkedActive: false,
      toggleEdit: false,
    });
  };

  async componentDidMount() {
    await this.getData();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    // console.log(this.props);
    firebase
      .database()
      .ref("Careers_Data")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };

  handleChangeImage = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      this.setState(() => ({ image }));
    }
  };
  handleUpdateImage = (e) => {
    if (e.target.files[0]) {
      const imageUpdate = e.target.files[0];

      this.setState(() => ({ imageUpdate }));
    }
  };

  handleCheck = () => {
    this.setState({
      checkedActive: !this.state.checkedActive,
    });
  };
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  addData = () => {
    const { jobTitle, emailLink, checkedActive, data, jobPara } = this.state;
    var userDataKey = firebase
      .database()
      .ref()
      .child("Careers_Data")
      .push().key;

    this.setState({
      loaderToggle: true,
    });
    // console.log(checkedActive);

    var userDataKey = firebase
      .database()
      .ref()
      .child("Careers_Data")
      .push().key;

    var obj = {
      jobTitle,
      emailLink,
      checkedActive,
      data,
      jobPara,
      createAt: new Date().toLocaleString(),
      userDataKey,
    };
    if (
      jobTitle === "" ||
      jobTitle === undefined ||
      jobPara === "" ||
      jobPara === undefined ||
      data === "" ||
      data === undefined ||
      emailLink === "" ||
      emailLink === undefined ||
      checkedActive === "" ||
      checkedActive === undefined
    ) {
      alert("Please Fill All field");
      this.setState({
        loaderToggle: false,
      });
    } else {
      firebase
        .database()
        .ref("Careers_Data/" + userDataKey)
        .set(obj)
        .then((e) => {
          this.setState({
            // success: "Successfully",
            // visible: true,
            modal: !this.state.modal,
            loaderToggle: false,
          });
        })
        .catch((error) => {
          console.log("Error: ", error.message);
        });
    }
  };

  deleteUser = (id) => {
    // console.log("id", id);
    database
      .ref("Careers_Data/" + id)
      .remove()
      .then((success) => {})
      .catch((e) => {
        console.log("Error: ", e);
      });
  };
  handleUpdate = (
    jobTitle,
    jobPara,
    emailLink,
    checkedActive,
    data,
    userDataKey
  ) => {
    this.setState({
      jobTitle,
      jobPara,
      emailLink,
      checkedActive,
      data,
      userDataKey,
      modal: !this.state.modal,
      toggleEdit: true,
    });
  };

  editUser = () => {
    const {
      jobTitle,
      emailLink,
      checkedActive,
      data,
      jobPara,
      userDataKey,
    } = this.state;
    this.setState({
      loaderToggle: true,
    });

    var obj = {
      jobTitle,
      emailLink,
      checkedActive,
      data,
      jobPara,
      updateDate: new Date().toLocaleString(),
      userDataKey,
    };
    if (
      jobTitle === "" ||
      jobTitle === undefined ||
      jobPara === "" ||
      jobPara === undefined ||
      data === "" ||
      data === undefined ||
      emailLink === "" ||
      emailLink === undefined ||
      checkedActive === "" ||
      checkedActive === undefined
    ) {
      alert("Please Fill All field");
      this.setState({
        loaderToggle: false,
      });
    } else {
      firebase
        .database()
        .ref("Careers_Data/" + userDataKey)
        .update(obj)
        .then((e) => {
          this.setState({
            // success: "Successfully",
            // visible: true,
            modal: !this.state.modal,
            loaderToggle: false,
          });
        })
        .catch((error) => {
          console.log("Error: ", error.message);
        });
    }
  };

  render() {
    const {
      loaderToggle,
      arrData,
      toggleEdit,
      checkedActive,
      jobTitle,
      emailLink,

      data,
    } = this.state;

    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
       
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>Careers</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="doctors" class="doctors">
              <div class="container">
                <div class="d-flex justify-content-end">
                  <button
                    class="btn btn-primary mb-3 "
                    onClick={() => this.addUserModal()}
                  >
                    Add Job
                  </button>
                </div>
                <div class="row">
                  {this.state.arrData.map((e, i) => {
                    // console.log("e", e);
                    return (
                      <div class="col-lg-12 mb-4" key={i}>
                        <div class="card shadow-sm">
                          <div class="card-body">
                            {e.checkedActive === true ? (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  // position: "absolute",
                                  color: "green",
                                }}
                              >
                                Active
                              </p>
                            ) : (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  // position: "absolute",
                                  // fontWeight: 600 ,
                                  color: "red",
                                }}
                              >
                                Not Active
                              </p>
                            )}
                            <h5 class="card-title">{e.jobTitle}</h5>
                            <p class="card-text">{e.jobPara}</p>
                            {renderHTML(e.data)}
                            <p>Email: {e.emailLink}</p>
                          </div>
                          <div class="d-flex justify-content-end ml-4 mb-3 p-3">
                         
                            <button
                              type="button"
                              style={{marginRight: '0.5rem'}}
                              class="btn btn-primary btn-sm "
                              onClick={() =>
                                this.handleUpdate(
                                  e.jobTitle,
                                  e.jobPara,
                                  e.emailLink,
                                  e.checkedActive,
                                  e.data,
                                  e.userDataKey
                                )
                              }
                            >
                              <i
                                class="bi bi-pen"
                                style={{
                                  // color: "green",
                                  // marginRight: "0.5rem",
                                }}
                              ></i>{" "}
                              Edit
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure want to Delete this User?"
                                  )
                                ) {
                                  this.deleteUser(e.userDataKey);
                                }
                              }}
                            >
                              <i
                                class="bi bi-trash fa-2x"
                                // style={{ color: "red" }}
                              ></i>{" "}
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
          <div>
            <Modal
              size="xl"
              isOpen={this.state.modal}
              toggle={this.state.toggle}
              //   className={className}
              //   backdrop={backdrop}
              backdrop="static"
              //   keyboard={keyboard}
            >
              <div
                class="mt-4 mr-4 ml-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5 toggle={this.state.toggle}>
                  {toggleEdit === false ? "Add User" : "Edit User"}
                </h5>
                {loaderToggle && <Spinner color="primary" />}
              </div>
              <hr />
              <ModalBody>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label class="small mb-1" for="inputEmailAddress">
                      Job Title{" "}
                    </label>
                    <input
                      class="form-control "
                      id="inputEmailAddress"
                      placeholder="Enter Job Title"
                      name="jobTitle"
                      type="text"
                      value={this.state.jobTitle}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label class="small mb-1" for="inputEmailAddress">
                      Job Paragraph (Max Length 100 words)
                    </label>
                    <textarea
                      class="form-control"
                      // id="inputEmailAddress"
                      // placeholder="Enter Job Title"
                      name="jobPara"
                      type="text"
                      value={this.state.jobPara}
                      onChange={this.handleChange}
                      min="1"
                      maxlength="100"
                    ></textarea>
                  </div>

                  <div class="form-group col-md-12">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        // plugins: [Paragraph, Bold, Italic, Essentials],
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                          // "bold",
                          // "italic",
                        ],
                      }}
                      // config={{
                      //   toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                      //     'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']
                      // }}
                      // editor={editorConfiguration}
                      // data="<p>Hello from CKEditor 5!</p>"
                      data={this.state.data}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // console.log({ event, editor, data });
                        this.setState({ data });
                      }}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                    {/* <label for="exampleFormControlTextarea1">Description</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Enter Description"
                      name="description"
                      type="text"
                      value={this.state.description}
                      onChange={this.handleChange}
                    ></textarea> */}
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label class="small mb-1">Email Link</label>
                    <input
                      class="form-control "
                      // id="inputEmailAddress"
                      placeholder="Enter Email Link"
                      name="emailLink"
                      type="text"
                      value={this.state.emailLink}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  class="form-check"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <input
                    checked={checkedActive}
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                    name="checkedActive"
                    value={checkedActive}
                    onChange={this.handleCheck}
                  />
                  <label class="form-check-label" for="exampleCheck1">
                    Active Job
                  </label>
                </div>
                <div>
                  {toggleEdit === false ? (
                    <Button
                      className="mr-2"
                      color="primary"
                      onClick={
                        () => this.addData()
                        // this.setState({
                        //   modal: !this.state.modal,
                        // })
                      }
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      className="mr-2"
                      color="primary"
                      onClick={
                        () => this.editUser()
                        // this.setState({
                        //   modal: !this.state.modal,
                        // })
                      }
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        modal: !this.state.modal,
                        imageEdit: "",
                        image: "",
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
