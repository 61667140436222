import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import "../../assets/css/style.css";
var I18n = require("react-redux-i18n").I18n;

export default class BankFinance extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>{I18n.t("home.servicesubHeading12").toUpperCase()}</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="blog" class="blog">
              <div class="container" data-aos="fade-up">
                <div class="row">
                  <div class="col-lg-12 entries">
                    <article class="entry">
                      <h2 className="mb-4" style={{ textAlign: "center" }}>
                        {I18n.t("npo.heading1").toUpperCase()}
                      </h2>
                      <div class="entry-content">
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                          {I18n.t("npo.content1")}
                        </p>
                        <br />
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                          {I18n.t("npo.content2")}
                        </p>
                        <br />
                        <ul style={{ listStyleType: "none" }}>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList1")}{" "}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList2")}{" "}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList3")}{" "}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList4")}{" "}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList5")}{" "}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList6")}{" "}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList7")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList8")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList9")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList10")}
                          </li>
                          <li>
                            <i class="icofont-check-circled"></i>{" "}
                            {I18n.t("npo.heading1contentList11")}
                          </li>
                        </ul>
                      </div>

                      <p>{I18n.t("npo.content3")}</p>
                    </article>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
