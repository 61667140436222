import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/img/logo192.png";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import firebase from "../../config/firebase";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      arrData: [],
    };
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;

    firebase
      .database()
      .ref("User_Data")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };
  render() {
    const { arrData } = this.state;
    // console.log(arrData);
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            {/* <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1> {I18n.t("navbar.navHeading3")}</h1>
              </div>
            </section> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>{I18n.t("navbar.navHeading3")}</h2>
                    <p>{I18n.t("OurPros.content")}</p>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="team" class="team">
              <div class="container">
                <div class="row">
                  {this.state.arrData.map((e, i) => {
                    return (
                      e.checkedActive === true && (
                        <div
                          class="col-lg-3 col-md-4 col-xs-6 d-flex  mt-4"
                          key={i}
                        >
                          <div class="member" data-aos="fade-up">
                            <div class="member-img">
                              <img
                                src={e.downloadURL}
                                style={{ height: 250, width: 250 }}
                                class="img-fluid"
                                alt=""
                              />
                              {/* <div class="social">
                                <a href="">
                                  <i class="bi bi-twitter"></i>
                                </a>
                                <a href="">
                                  <i class="bi bi-facebook"></i>
                                </a>
                                <a href="">
                                  <i class="bi bi-instagram"></i>
                                </a>
                                <a href="">
                                  <i class="bi bi-linkedin"></i>
                                </a>
                              </div> */}
                            </div>
                            <div class="member-info">
                              <h4>{e.name}</h4>
                              <span>{e.designation}</span>
                              <p>{e.description}</p>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
