import React, { useState } from "react";
import CompanySecretarialServices from "../assets/imgHome/CompanySecretarialServices.jpg";
import Commerical from "../assets/imgHome/Commerical.jpg";
import Corporate from "../assets/imgHome/Corporate.jpg";
import Disputeresolution from "../assets/imgHome/Disputeresolution.jpg";
import EmploymentLabour from "../assets/imgHome/EmploymentLabour.jpg";
import Finacebanking from "../assets/imgHome/Finacebanking.jpg";
import IntellectualProperty from "../assets/imgHome/IntellectualProperty.jpg";
import PrivateClientsServices from "../assets/imgHome/PrivateClientsServices.jpg";
import PropertyRealEstate from "../assets/imgHome/PropertyRealEstate.jpg";
import FamilyLaw from "../assets/imgHome/FamilyLaw.jpg";
import TaxationImg from "../assets/imgHome/taxation.jpg";
import NGO from "../assets/imgHome/ngo-npo.jpg";
import "./services.css";
var I18n = require("react-redux-i18n").I18n;
const CTA = () => {
  const [state, setState] = useState({
    arr: [
      {
        id: 1,
        link: "/Banking-and-finance",
        imageCardBg: Finacebanking,
        name: "home.servicesubHeading1",
      },
      {
        id: 2,
        link: "/company-secretarial",
        imageCardBg: CompanySecretarialServices,
        name: "home.servicesubHeading2",
      },
      {
        id: 3,
        link: "/commercial",
        imageCardBg: Commerical,
        name: "home.servicesubHeading3",
      },
      {
        id: 4,
        link: "/corporate",
        imageCardBg: Corporate,
        name: "home.servicesubHeading4",
      },
      {
        id: 5,
        link: "/dispute-resolution-litigation",
        imageCardBg: Disputeresolution,
        name: "home.servicesubHeading5",
      },
      {
        id: 6,
        link: "/employment-and-labour",
        imageCardBg: EmploymentLabour,
        name: "home.servicesubHeading6",
      },
      {
        id: 7,
        link: "/intellectual-property",
        imageCardBg: IntellectualProperty,
        name: "home.servicesubHeading7",
      },
      {
        id: 8,
        link: "/private-client-services",
        imageCardBg: PrivateClientsServices,
        name: "home.servicesubHeading8",
      },
      {
        id: 9,
        link: "/property-&-real-estate",
        imageCardBg: PropertyRealEstate,
        name: "home.servicesubHeading9",
      },
      {
        id: 10,
        link: "/family-law",
        imageCardBg: FamilyLaw,
        name: "home.servicesubHeading10",
      },
      {
        id: 11,
        link: "/taxation",
        imageCardBg: TaxationImg,
        name: "home.servicesubHeading11",
      },
      {
        id: 12,
        link: "/ngo",
        imageCardBg: NGO,
        name: "home.servicesubHeading12",
      },
    ],
  });
  return (
    <section id="services" class="services ">
      <div class="container">
        <div class="section-title pt-5 aos-init aos-animate" data-aos="fade-up">
          <h2>{I18n.t("home.serviceHeading")}</h2>
        </div>

        <div class="row">
          {state.arr.map((e, i) => {
            return (
              <div class="col-md-4">
                <div class="profile-card-6" style={{}}>
                  <a href={e.link}>
                    <img
                      src={e.imageCardBg}
                      class="img img-responsive"
                      style={{
                        width: "100%",
                        height: '100%'
                      }}
                    />
                    <div class="profile-name">{I18n.t(e.name)}</div>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CTA;
