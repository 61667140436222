import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import '../src/assets/css/style.css'
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

serviceWorkerRegistration.register();

if (module.hot) {
  module.hot.accept();
}
