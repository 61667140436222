import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import renderHTML from "react-render-html";
import firebase from "../../config/firebase";
var database = firebase.database();
var I18n = require("react-redux-i18n").I18n;
export default class contact extends Component {
  constructor() {
    super();
    this.state = {};
  }

  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    // console.log(this.props);
    firebase
      .database()
      .ref("Settings")

      .on("value", (snapshot) => {
        var childData = snapshot.val();

        for (var key in childData) {
          // console.log("Success: ", childData[key]);
          this.setState({
            address: childData[key].address,
            callus: childData[key].callus,
            checkedActive: childData[key].checkedActive,
            createAt: childData[key].createAt,
            emailWork: childData[key].emailWork,
            timing: childData[key].timing,
            updateAt: childData[key].updateAt,
            userDataKey: childData[key].userDataKey,
          });
        }
      });
  };
  render() {
    const { callus, address, emailWork, timing } = this.state;

    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            {/* <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1> {I18n.t("aboutData.content2").toUpperCase()}</h1>
              </div>
            </section> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2> {I18n.t("aboutData.content2").toUpperCase()}</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="contact" class="contact">
              <div class="container">
                <div class="row">
                  <div class="col-md-4 col-lg-4" data-aos="fade-right ">
                    <div class="info" style={{ padding: "1rem" }}>
                      <div class="address">
                        <i class="bi bi-geo-alt"></i>
                        <h4>{I18n.t("aboutData.content8")}:</h4>
                        {/* <p>
                          201 Asad Chambers near Sindh High Court, Saddar
                          Karachi
                        </p> */}
                        {address && renderHTML(address)}
                      </div>

                      <div class="email">
                        <i class="bi bi-envelope"></i>
                        <h4>{I18n.t("aboutData.content6")}:</h4>
                        {/* <p>info@sheikhandqazi.com</p> */}

                        <p> {emailWork && `${emailWork}`}</p>
                      </div>

                      <div class="phone">
                        <i class="bi bi-phone"></i>
                        <h4>{I18n.t("aboutData.content10")}:</h4>
                        {/* <p>021 - 38887114</p> */}
                        {callus && renderHTML(`${callus}`)}
                      </div>
                      <div class="phone">
                        <i class="bi bi-phone"></i>
                        <h4>{I18n.t("aboutData.content9")}:</h4>
                        {/* <p>
                          {" "} */}
                          {timing && renderHTML(`${timing}`)}
                          {/* (
                          {I18n.t("aboutData.contentSchedule1")})<br /> */}
                          {/* 10:00 AM - 4:00 PM (
                          {I18n.t("aboutData.contentSchedule2")}) */}
                        {/* </p> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8" data-aos="fade-left">
                    <iframe
                      // style={{ border: 0, width: "100%", height: " 383px" }}
                      // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                      // frameborder="0"
                      // allowfullscreen
                      class="mb-4 mb-lg-0"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.149102655199!2d67.0211734143711!3d24.858756651449347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33fd3e2b3e0a3%3A0xf5ff965d653d0bde!2sAsad%20chamber!5e0!3m2!1sen!2s!4v1617538888686!5m2!1sen!2s"
                      frameborder="0"
                      style={{ border: 0, width: "100%", height: "383px" }}
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
