import React, { Component } from "react";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import firebase from "../../config/firebase";
import "../../assets/css/style.css";
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      arrData: [],
    };
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    // console.log("this.props", this.props.match.params.id);
    const paramsId = this.props.match.params.id;
    firebase
      .database()
      .ref("Statutes_Data")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          //   console.log("Success: ", childData);
        });
        this.setState({
          //   arrData: newArr.reverse(),
          arrData: newArr.filter((e) => e.categoryId === paramsId).reverse(),
        });
      });
  };
  render() {
    const { title, para, detail, emailLink, arrData } = this.state;
    const headingName = arrData.map((e) => e.categoryName);
    // console.log(headingName[0]);
    // console.log(arrData);
    var er = this.props.match.path.replace(/[#_/:-]/g, " ").toUpperCase();
    var editPath = er.replace("ID", "");

    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            <section id="breadcrumbs" className="breadcrumbs">
              <div className="breadcrumb-hero">
                <div className="container">
                  <div className="breadcrumb-hero">
                    <h2>STATUTES | {arrData && headingName[0]}</h2>
                  </div>
                </div>
              </div>
              <div className="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{arrData && headingName[0]}</li>
                </ol>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}

            <section id="blog" className="blog">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  {this.state.arrData.map((e, i) => {
                    // console.log("e", e);
                    return (
                      e.checkedActive === true && (
                        <a href={e.downloadURL} target="_blank">
                          <div className="col-md-6 mb-4 " key={i}>
                            <div className="card ">
                              <div className="card-body">
                                <h5 className="card-title">{e.documentName}</h5>
                              </div>
                            </div>
                          </div>
                        </a>
                      )
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
        </div>

        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} className="back-to-top">
          <i className="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
