import React, { Component } from "react";
import renderHTML from "react-render-html";
import firebase from "../config/firebase";
var database = firebase.database();
var I18n = require("react-redux-i18n").I18n;
export default class Footer extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    // console.log(this.props);
    firebase
      .database()
      .ref("Settings")

      .on("value", (snapshot) => {
        var childData = snapshot.val();

        for (var key in childData) {
          // console.log("Success: ", childData[key]);
          this.setState({
            address: childData[key].address,
            callus: childData[key].callus,
            checkedActive: childData[key].checkedActive,
            createAt: childData[key].createAt,
            emailWork: childData[key].emailWork,
            timing: childData[key].timing,
            updateAt: childData[key].updateAt,
            userDataKey: childData[key].userDataKey,
            twitter: childData[key].twitter,
            facebook: childData[key].facebook,
            instagram: childData[key].instagram,
            linkedin: childData[key].linkedin,
          });
        }
      });
  };
  render() {
    const {
      callus,
      address,
      emailWork,
      twitter,
      facebook,
      instagram,
      linkedin,
    } = this.state;
 
    return (
      <div>
        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-6 footer-info">
                  <h3>{I18n.t("aboutData.heading1")}</h3>
                  <p>{I18n.t("aboutData.content1")}</p>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <h4>{I18n.t("aboutData.heading2")}</h4>
                  <ul>
                    <li>
                      <a href="/banking-and-finance">
                        {" "}
                        {I18n.t("home.servicesubHeading1")}
                      </a>
                    </li>
                    <li>
                      <a href="/company-secretarial">
                        {I18n.t("home.servicesubHeading2")}
                      </a>
                    </li>
                    <li>
                      <a href="/commercial">
                        {" "}
                        {I18n.t("home.servicesubHeading3")}
                      </a>
                    </li>
                    <li>
                      <a href="/corporate">
                        {" "}
                        {I18n.t("home.servicesubHeading4")}
                      </a>
                    </li>
                    <li>
                      <a href="/dispute-resolution-litigation">
                        {I18n.t("home.servicesubHeading5")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-3 col-md-6 footer-links">
                  <h4>{I18n.t("aboutData.heading3")}</h4>
                  <ul>
                    <li>
                      <a href="/employment-and-labour">
                        {" "}
                        {I18n.t("home.servicesubHeading6")}
                      </a>
                    </li>
                    <li>
                      <a href="/intellectual-property">
                        {" "}
                        {I18n.t("home.servicesubHeading7")}
                      </a>
                    </li>
                    <li>
                      <a href="/private-client-services">
                        {I18n.t("home.servicesubHeading8")}
                      </a>
                    </li>
                    <li>
                      <a href="/property-&-real-estate">
                        {I18n.t("home.servicesubHeading9")}
                      </a>
                    </li>
                    <li>
                      <a href="/property-&-real-estate">
                        {I18n.t("home.servicesubHeading10")}
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-contact">
                  <h4>{I18n.t("aboutData.content2")}</h4>
                  {address && renderHTML(address)}
                  {callus &&
                    renderHTML(` ${I18n.t("aboutData.content5")}: ${callus}`)}
                  {emailWork && renderHTML(`Email: ${emailWork}`)}

                  <div class="social-links">
                    <br />
                    {twitter !== "-" && (
                      <a href={twitter} class="twitter" target="_blank">
                        <i class="bi bi-twitter"></i>
                      </a>
                    )}
                    {facebook !== "-" && (
                      <a href={facebook} class="facebook" target="_blank">
                        <i class="bi bi-facebook"></i>
                      </a>
                    )}
                    {instagram !== "-" && (
                      <a href={instagram} class="facebook" target="_blank">
                        <i class="bi bi-instagram"></i>
                      </a>
                    )}
                    {linkedin !== "-" && (
                      <a href={linkedin} class="facebook" target="_blank">
                        <i class="bi bi-linkedin"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright">&copy; {I18n.t("aboutData.content3")}</div>
            <div class="credits">
              Developed by{" "}
              <a href="https://eight-1-eight.web.app/">Eight-1-Eight</a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
