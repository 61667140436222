import React, { Component } from "react";
import "../../App.css";
import logo from "../../assets/userLogo.png";
import firebase from "../../config/firebase";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { update_user } from "../../store/actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
// import '../../assets/css/style.css'
import "./Login.css";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
class Login extends Component {
  notificationSystem = React.createRef();
  constructor() {
    super();
    this.state = {
      modal: false,
      trainersUser: [],
      email: "",
      password: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    //  this.state.displayEmail && this.getResturantUserData()
    // this.authListener()
    // this.getadminUserData();
    this.getCustomertUserData();
  }

  getCustomertUserData = () => {
    firebase
      .database()
      .ref("Admin")
      .on("value", (snapshot) => {
        var arr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          arr.push(childData);
        });
        this.setState({
          trainersUser: arr,
        });
      });
  };

  authListener() {
    // const { history } = this.props
    firebase.auth().onAuthStateChanged((user) => {
      // console.log(user)
      if (user) {
        // history.push("/Login");
        // history.push("/");
        this.setState({
          displayEmail: user.email,
        });
      } else {
        // No user is signed in.
        //       history.push("/Login");
        // history.push('/emailverification')
      }
    });
  }

  handleSubmit = (event) => {
    this.setState({
      loaderToggle: !this.state.loaderToggle,
    });
    const { history } = this.props;

    const {
      email,
      password,

      trainersUser,
    } = this.state;
    const loginAsAdmin = trainersUser.filter((item) => item.email === email);

    // NotificationManager.success('Success message', 'Title here');

    if (loginAsAdmin.length !== 0) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          this.setState({
            openSnack: true,
            loaderToggle: !this.state.loaderToggle,
          });
          this.props.store_user(user);
          setTimeout(function() {
            history.push("/dashboard");
          }, 2000);
          // }
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === "auth/invalid-email") {
            const notification = this.notificationSystem.current;
            notification.addNotification({
              // message: errorMessage,
              message: "Please add Todo",
              level: "error",
            });
          }
          if (errorCode === "auth/wrong-password") {
            // NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
            // NotificationManager.success('Success message', 'Title here');
            NotificationManager.error(
              "Error message",
              <span style={{ color: "white" }}>{errorMessage}</span>
            );
            // const notification = this.notificationSystem.current;
            // notification.addNotification({
            //   // message: errorMessage,
            //   message: "Please add Todo",
            //   level: "error",
            // });
          }
          if (errorCode === "auth/user-not-found") {
            const notification = this.notificationSystem.current;
            notification.addNotification({
              message: errorMessage,
              level: "error",
            });
          }

          console.error("Error writing document: ", error);
        });
    } else {
      alert("There is no user registered");
    }
  };
  /*************************************************/

  handleKeyPress = (e) => {
    // if (event.charCode == 13) {
    //     this.submitLogin()
    // }
    if (e.charCode === 13) {
      this.handleSubmit();
    }
    // if (e.keyCode === 13) {
    //   alert('Enter... (KeyDown, use keyCode)');
    // }
    //   if(e.key === 'Enter') {
    //   console.log('Enter key pressed');
    // }
  };

  forgotPassword = () => {
    const { emailForgot } = this.state;

    var auth = firebase.auth();
    var emailAddress = emailForgot;

    if (emailForgot === "" || emailForgot === undefined) {
      alert("Please Enter Valid Email Address");
    } else {
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(() => {
          // console.log("success");
          alert("Email sent!, Check Your Inbox or Spam Folder");
          // Email sent.
          this.setState({
            modal: !this.state.modal,
          });
        })
        .catch((error) => {
          console.log("error", error.message);
          alert("Error: ", error.message);
          // An error happened.
        });
    }
  };
  render() {
    const { email, password } = this.state;
    const { user } = this.props;
    var currentId = user.user && user.user.user.uid;
    if (currentId) return <Redirect to="/dashboard" />;
    return (
      <div className="cl">
        <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
            <main>
              <div class="container ">
                <div class="row ">
                  <div class="col-lg-6 offset-md-3 mt-5">
                    <div class="card shadow-lg border-0 rounded-lg mt-5">
                      <div
                        class="mt-5"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <a href="/">
                          {/* <span>Eterna</span> */}
                          <img
                            className="logoSize img img-responsive"
                            src={logo}
                            alt="userLogo"
                            // style={{ width: "350px" }}
                          />
                        </a>
                      </div>
                      <div class="card-header">
                        <h3 class="text-center font-weight-light my-4">
                          Login
                        </h3>
                      </div>
                      <div class="card-body">
                        {/* <form> */}
                        <div class="form-group">
                          <label class="small mb-1" for="inputEmailAddress">
                            Email
                          </label>
                          <input
                            autoComplete="off"
                            class="form-control "
                            type="email"
                            placeholder="Enter email address"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div class="form-group">
                          <label class="small mb-1" for="inputPassword">
                            Password
                          </label>
                          <input
                            class="form-control "
                            type="password"
                            placeholder="Enter password"
                            // fullWidth
                            helpertext="Incorrect entry."
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                          />
                        </div>

                        <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                          <button
                            class="btn btn-light"
                            onClick={() =>
                              this.setState({
                                modal: !this.state.modal,
                              })
                            }
                          >
                            Forgot Password?
                          </button>
                          <button
                            disabled={
                              email === "" || password === "" ? true : false
                            }
                            class="btn btn-outline-primary"
                            // href="/Dashboard"
                            onClick={() => this.handleSubmit()}
                          >
                            Login
                          </button>
                        </div>
                        {/* </form> */}
                      </div>
                      {/* <div class="card-footer text-center">
                        <div class="small">
                          <a href="register.html">Need an account? Sign up!</a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          {/* <div id="layoutAuthentication_footer">
            <footer class="py-4 bg-light mt-auto">
              <div class="container-fluid">
                <div class="d-flex align-items-center justify-content-between small">
                  <div class="text-muted">
                    Copyright &copy; Your Website 2020
                  </div>
                  <div>
                    <a href="#">Privacy Policy</a>
                    &middot;
                    <a href="#">Terms &amp; Conditions</a>
                  </div>
                </div>
              </div>
            </footer>
          </div> */}
        </div>
        <div>
          <Modal
            size="lg"
            isOpen={this.state.modal}
            toggle={this.state.toggle}
            //   className={className}
            //   backdrop={backdrop}
            backdrop="static"
            //   keyboard={keyboard}
          >
            <ModalHeader toggle={this.state.toggle}>
              Did you forgot your password?
            </ModalHeader>
            <ModalBody>
              Enter your email address you're using for your account below and
              we will send you a password reset link
              <div class="row">
                <div class="form-group col-md-12">
                  <label class="small mb-1" for="inputEmailAddress">
                    Email Address
                  </label>
                  <input
                    class="form-control"
                    id="inputEmailAddress"
                    type="email"
                    placeholder="Enter email"
                    name="emailForgot"
                    value={this.state.emailForgot}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div>
                <Button
                  color="primary"
                  // onClick={() =>
                  //   this.setState({
                  //     modal: !this.state.modal,
                  //   })
                  // }
                  onClick={() => {
                    this.forgotPassword();
                  }}
                >
                  Request Reset Link
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      modal: !this.state.modal,
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

// export default Login;
const mapStateToProps = (state) => {
  return {
    user: state.reducers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    store_user: (userlogin) => dispatch(update_user(userlogin)),
  };
};
// export default withStyles(styles)(Login);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
