import React, { Component } from "react";
import Navbar from "../../config/NavAdmin";
import firebase from "../../config/firebase";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import renderHTML from "react-render-html";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
var database = firebase.database();
ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "imageUpload",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      checkedActive: false,
      toggleEdit: false,
      arrData: [],
      image: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    firebase
      .database()
      .ref("Categories")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };

  handleChangeImage = (e) => {
    const image = e.target.files[0];
    // console.log("image", image);
    // if (image.type != "application/pdf") {
    //   // alert("Only PDF are allowed");
    //   // this.setState({ imageData: undefined });
    //   //   setError("selectedfile", {
    //   //       type: "filetype",
    //   //       message: "Only PDFs are valid."
    //   //   });
    //   //   return;
    //   this.setState(() => ({ image }));
    // } else {
    //   alert("Only PDF are allowed");
    //   this.setState(() => ({ image }));
    // }
    if (e.target.files[0]) {
      const image = e.target.files[0];

      this.setState(() => ({ image }));
    }
  };
  handleUpdateImage = (e) => {
    if (e.target.files[0]) {
      const imageUpdate = e.target.files[0];

      this.setState(() => ({ imageUpdate }));
    }
  };

  handleCheck = () => {
    this.setState({
      checkedActive: !this.state.checkedActive,
    });
  };
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  addData(e) {
    e.preventDefault();
    const { categoryName, checkedActive, selectType } = this.state;

    var userDataKey = firebase
      .database()
      .ref()
      .child("Categories")
      .push().key;

    var obj = {
      categoryName,
      checkedActive,
      createAt: new Date().toLocaleString(),
      userDataKey,
      selectType,

      // downloadURL,
    };

    firebase
      .database()
      .ref("Categories/" + userDataKey)
      .set(obj)
      .then((e) => {
        this.setState({
          // success: "Successfully",
          // visible: true,
          modal: !this.state.modal,
          loaderToggle: false,
        });
      })
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  }

  deleteUser = (id) => {
    // console.log("id", id);
    database
      .ref("Categories/" + id)
      .remove()
      .then((success) => {})
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  editUser(e) {
    e.preventDefault();
    const {
      categoryName,

      checkedActive,

      userDataKey,
      selectType,
    } = this.state;
    this.setState({
      loaderToggle: true,
    });

    var obj = {
      categoryName,
      checkedActive,
      updateDate: new Date().toLocaleString(),
      userDataKey,
      selectType,
    };

    firebase
      .database()
      .ref("Categories/" + userDataKey)
      .update(obj)
      .then((e) => {
        this.setState({
          // success: "Successfully",
          // visible: true,
          modal: !this.state.modal,
          loaderToggle: false,
        });
      })
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  }

  render() {
    const {
      loaderToggle,
      arrData,
      toggleEdit,
      checkedActive,
      categoryName,
      documentLink,

      data,
      // imageData,
      linkOrAttach,
      image,
      imageEdit,
      selectType,
    } = this.state;

    var imageName = this.state.image && this.state.image.name;
    var imageData = imageName ? imageName : imageEdit;
    // console.log("toggleEdit,", toggleEdit);
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>CATEGORIES | ADMIN</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="doctors" class="doctors">
              <div class="container">
                <div class="d-flex justify-content-end">
                  <button
                    class="btn btn-primary mb-3 "
                    // style={{marginLeft: '0.6rem'}}
                    onClick={() =>
                      this.setState({
                        modal: !this.state.modal,
                        categoryName: "",
                        documentLink: "",
                        checkedActive: "",
                        linkOrAttach: "",
                        selectType: "",
                        checkedActive: false,
                        toggleEdit: false,
                      })
                    }
                  >
                    Add Category
                  </button>
                </div>
                <div class="row">
                  {this.state.arrData.map((e, i) => {
                    // console.log("e", e);
                    return (
                      <div class="col-lg-6 col-md-6 mb-4" key={i}>
                        <div class="card shadow">
                          <div class="card-body">
                            {e.checkedActive === true ? (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  // position: "absolute",
                                  color: "green",
                                }}
                              >
                                Active
                              </p>
                            ) : (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  // position: "absolute",
                                  // fontWeight: 600 ,
                                  color: "red",
                                }}
                              >
                                Not Active
                              </p>
                            )}
                            <h5 class="card-title">{e.categoryName}</h5>
                            <h6 class="card-title">
                              Select Type: {e.selectType}
                            </h6>
                          </div>
                          <div class="d-flex justify-content-end ml-4 mb-3 p-3">
                            <button
                              type="button"
                              style={{ marginRight: "0.5rem" }}
                              class="btn btn-primary btn-sm "
                              onClick={() =>
                                this.setState({
                                  categoryName: e.categoryName,

                                  checkedActive: e.checkedActive,
                                  selectType: e.selectType,
                                  createAt: e.createAt,
                                  linkOrAttach: e.linkOrAttach,
                                  userDataKey: e.userDataKey,

                                  modal: !this.state.modal,
                                  toggleEdit: true,
                                })
                              }
                            >
                              <i
                                class="bi bi-pen"
                                style={
                                  {
                                    // color: "green",
                                    // marginRight: "0.5rem",
                                  }
                                }
                              ></i>{" "}
                              Edit
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure want to Delete this User?"
                                  )
                                ) {
                                  this.deleteUser(e.userDataKey);
                                }
                              }}
                            >
                              <i
                                class="bi bi-trash fa-2x"
                                // style={{ color: "red" }}
                              ></i>{" "}
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
          <div>
            <Modal
              size="xl"
              isOpen={this.state.modal}
              toggle={this.state.toggle}
              //   className={className}
              //   backdrop={backdrop}
              backdrop="static"
              //   keyboard={keyboard}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: "1rem",
                  marginTop: "1rem",
                }}
              >
                <h5 toggle={this.state.toggle}>
                  {toggleEdit === false ? "Add User" : "Edit User"}
                </h5>
                {loaderToggle && <Spinner color="primary" />}
              </div>
              <hr />
              <ModalBody>
                <form
                  class="row g-3"
                  onSubmit={
                    toggleEdit === false
                      ? this.addData.bind(this)
                      : this.editUser.bind(this)
                  }
                >
                  <div class="col-md-4">
                    <label class="form-label" for="inputEmailAddress">
                      Category Name
                    </label>
                    <input
                      class="form-control "
                      id="inputEmailAddress"
                      //   placeholder="Enter e"
                      name="categoryName"
                      type="text"
                      value={this.state.categoryName}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="validationDefault04" class="form-label">
                      {" "}
                      Select Type
                    </label>
                    <select
                      class="form-select"
                      id="validationDefault04"
                      required
                      name="selectType"
                      value={this.state.selectType}
                      onChange={this.handleChange}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option> Pakistani Law</option>
                      <option> Statutes</option>
                    </select>
                  </div>
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        checked={checkedActive}
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        name="checkedActive"
                        value={checkedActive}
                        onChange={this.handleCheck}
                        // required
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        Active Category
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    {toggleEdit === false ? (
                      <Button
                        type="submit"
                        className="mr-2"
                        color="primary"
                        // onClick={
                        //   () => this.addData()
                        //   // this.setState({
                        //   //   modal: !this.state.modal,
                        //   // })
                        // }
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="mr-2"
                        color="primary"
                        // onClick={
                        //   () => this.editUser()
                        //   // this.setState({
                        //   //   modal: !this.state.modal,
                        //   // })
                        // }
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      style={{ marginLeft: "0.5rem" }}
                      color="secondary"
                      onClick={() =>
                        this.setState({
                          modal: !this.state.modal,
                          imageEdit: "",
                          image: "",
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </ModalBody>
            </Modal>
          </div>
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
