import React, { Component } from "react";
import Navbar from "../../config/NavAdmin";
import firebase from "../../config/firebase";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import renderHTML from "react-render-html";

import { Button, Modal, ModalBody, Spinner } from "reactstrap";
var database = firebase.database();
ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "imageUpload",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      checkedActive: false,
      toggleEdit: false,
      arrData: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    // console.log(this.props);
    firebase
      .database()
      .ref("Settings")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };

  handleChangeImage = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      this.setState(() => ({ image }));
    }
  };
  handleUpdateImage = (e) => {
    if (e.target.files[0]) {
      const imageUpdate = e.target.files[0];

      this.setState(() => ({ imageUpdate }));
    }
  };

  handleCheck = () => {
    this.setState({
      checkedActive: !this.state.checkedActive,
    });
  };
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  addData(e) {
    e.preventDefault();
    const {
      emailWork,
      timing,
      checkedActive,
      address,
      callus,
      twitter,
      facebook,
      instagram,
      linkedin,
    } = this.state;
    var userDataKey = firebase
      .database()
      .ref()
      .child("Settings")
      .push().key;

    this.setState({
      loaderToggle: true,
    });
    // console.log(checkedActive);

    var userDataKey = firebase
      .database()
      .ref()
      .child("Settings")
      .push().key;

    var obj = {
      emailWork,
      timing,
      checkedActive,
      address,
      callus,
      createAt: new Date().toLocaleString(),
      userDataKey,
      twitter: `${twitter}`,
      facebook: `${facebook}`,
      instagram: `${instagram}`,
      linkedin: `${linkedin}`,
    };

    firebase
      .database()
      .ref("Settings/" + userDataKey)
      .set(obj)
      .then((e) => {
        this.setState({
          // success: "Successfully",
          // visible: true,
          modal: !this.state.modal,
          loaderToggle: false,
        });
      })
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  }

  deleteUser = (id) => {
  
    database
      .ref("Settings/" + id)
      .remove()
      .then((success) => {})
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  editUser(e) {
    e.preventDefault();
    const {
      emailWork,
      timing,
      checkedActive,
      address,
      callus,
      userDataKey,
      twitter,
      facebook,
      instagram,
      linkedin,
    } = this.state;

    this.setState({
      loaderToggle: true,
    });

    var obj = {
      emailWork,
      timing,
      checkedActive,
      address,
      callus,
      updateAt: new Date().toLocaleString(),
      userDataKey,
      twitter: `${twitter}`,
      facebook: `${facebook}`,
      instagram: `${instagram}`,
      linkedin: `${linkedin}`,
    };


    firebase
      .database()
      .ref("Settings/" + userDataKey)
      .update(obj)
      .then((e) => {
        this.setState({
          // success: "Successfully",
          // visible: true,
          modal: !this.state.modal,
          loaderToggle: false,
        });
      })
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  }

  render() {
    const {
      loaderToggle,
      arrData,
      toggleEdit,
      checkedActive,
      emailWork,
      emailLink,

      data,
    } = this.state;

    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>Settings</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="doctors" class="doctors">
              <div class="container">
                <div class="d-flex justify-content-end">
                  <button
                    disabled={arrData.length >= 1 ? true : false}
                    class="btn btn-primary mb-3 "
                    onClick={() => {
                      this.setState({
                        modal: !this.state.modal,
                        emailWork: "",
                        timing: "",
                        checkedActive: "",
                        address: "",
                        callus: "",
                        createAt: "",
                        twitter: "",
                        facebook: "",
                        instagram: "",
                        linkedin: "",
                        checkedActive: false,
                        toggleEdit: false,
                      });
                    }}
                  >
                    Add Data
                  </button>
                </div>
                <div class="row">
                  {this.state.arrData.map((e, i) => {
                    // console.log("e", e);
                    return (
                      <div class="col-lg-12 mb-4" key={i}>
                        <div class="card shadow-sm">
                          <div class="card-body">
                            {/* {e.checkedActive === true ? (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  // position: "absolute",
                                  color: "green",
                                }}
                              >
                                Active
                              </p>
                            ) : (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  // position: "absolute",
                                  // fontWeight: 600 ,
                                  color: "red",
                                }}
                              >
                                Not Active
                              </p>
                            )} */}
                            <h5 class="card-title">{e.emailWork}</h5>
                            {/* <p class="card-text">{e.jobPara}</p> */}
                            {renderHTML(e.timing)}
                            {renderHTML(e.address)}
                            {renderHTML(e.callus)}
                            {/* <p>Email: {e.emailLink}</p> */}
                          </div>
                          <div class="d-flex justify-content-end ml-4 mb-3 p-3">
                            <button
                              type="button"
                              style={{ marginRight: "0.5rem" }}
                              class="btn btn-primary btn-sm "
                              onClick={() => {
                                this.setState({
                                  emailWork: e.emailWork,
                                  timing: e.timing,
                                  checkedActive: e.checkedActive,
                                  address: e.address,
                                  callus: e.callus,
                                  createAt: e.createAt,
                                  userDataKey: e.userDataKey,
                                  modal: !this.state.modal,
                                  toggleEdit: true,
                                  twitter: e.twitter,
                                  facebook: e.facebook,
                                  instagram: e.instagram,
                                  linkedin: e.linkedin,
                                });
                              }}
                            >
                              <i
                                class="bi bi-pen"
                                style={
                                  {
                                    // color: "green",
                                    // marginRight: "0.5rem",
                                  }
                                }
                              ></i>{" "}
                              Edit
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure want to Delete this User?"
                                  )
                                ) {
                                  this.deleteUser(e.userDataKey);
                                }
                              }}
                            >
                              <i
                                class="bi bi-trash fa-2x"
                                // style={{ color: "red" }}
                              ></i>{" "}
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
          <div>
            <Modal
              size="xl"
              isOpen={this.state.modal}
              toggle={this.state.toggle}
              //   className={className}
              //   backdrop={backdrop}
              backdrop="static"
              //   keyboard={keyboard}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: "1rem",
                  marginTop: "1rem",
                }}
              >
                <h5 toggle={this.state.toggle}>
                  {toggleEdit === false ? "Add Contact" : "Edit Contact"}
                </h5>
                {loaderToggle && <Spinner color="primary" />}
              </div>
              <hr />
              <ModalBody>
                <form
                  class="row g-3"
                  onSubmit={
                    toggleEdit === false
                      ? this.addData.bind(this)
                      : this.editUser.bind(this)
                  }
                >
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label class="small mb-1" for="inputEmailAddress">
                        Email
                      </label>
                      <input
                        class="form-control "
                        id="inputEmailAddress"
                        placeholder="Enter Work Email"
                        name="emailWork"
                        type="text"
                        value={this.state.emailWork}
                        onChange={this.handleChange}
                        required
                      />
                      <br />
                    </div>
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="inputEmailAddress">
                        Timing
                      </label>
                      <CKEditor
                        // required
                        editor={ClassicEditor}
                        config={{
                          // plugins: [Paragraph, Bold, Italic, Essentials],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            // "bold",
                            // "italic",
                          ],
                        }}
                        data={this.state.timing}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          // console.log({ event, editor, data });
                          this.setState({ timing: data });
                        }}
                        onBlur={(event, editor) => {
                          // console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log("Focus.", editor);
                        }}
                      />
                      <br />
                    </div>
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="inputEmailAddress">
                        Call Us
                      </label>
                      <CKEditor
                        // required
                        editor={ClassicEditor}
                        config={{
                          // plugins: [Paragraph, Bold, Italic, Essentials],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            // "bold",
                            // "italic",
                          ],
                        }}
                        // config={{
                        //   toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                        //     'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']
                        // }}
                        // editor={editorConfiguration}
                        // data="<p>Hello from CKEditor 5!</p>"
                        data={this.state.callus}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          // console.log({ event, editor, data });

                          this.setState({ callus: data });
                        }}
                        onBlur={(event, editor) => {
                          // console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log("Focus.", editor);
                        }}
                      />
                      {/* <label for="exampleFormControlTextarea1">Description</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Enter Description"
                      name="description"
                      type="text"
                      value={this.state.description}
                      onChange={this.handleChange}
                    ></textarea> */}
                    </div>
                    <div class="form-group col-md-12">
                      <label class="small mb-1" for="inputEmailAddress">
                        Address
                      </label>
                      <CKEditor
                        // required
                        editor={ClassicEditor}
                        config={{
                          // plugins: [Paragraph, Bold, Italic, Essentials],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            // "bold",
                            // "italic",
                          ],
                        }}
                        data={this.state.address}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          // console.log({ event, editor, data });
                          this.setState({ address: data });
                        }}
                        onBlur={(event, editor) => {
                          // console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log("Focus.", editor);
                        }}
                      />
                      <br />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="basic-url" class="form-label">
                        Twitter
                      </label>
                      <div class="input-group mb-3">
                        {/* <span class="input-group-text" id="basic-addon3">
                          https://
                        </span> */}
                        <input
                          class="form-control"
                          id="basic-url"
                          aria-describedby="basic-addon3"
                          name="twitter"
                          type="text"
                          value={this.state.twitter}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="basic-url" class="form-label">
                        Facebook
                      </label>
                      <div class="input-group mb-3">
                        {/* <span class="input-group-text" id="basic-addon3">
                          https://
                        </span> */}
                        <input
                          class="form-control"
                          id="basic-url"
                          aria-describedby="basic-addon3"
                          name="facebook"
                          type="text"
                          value={this.state.facebook}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="basic-url" class="form-label">
                        Instagram
                      </label>
                      <div class="input-group mb-3">
                        {/* <span class="input-group-text" id="basic-addon3">
                          https://
                        </span> */}
                        <input
                          class="form-control"
                          id="basic-url"
                          aria-describedby="basic-addon3"
                          name="instagram"
                          type="text"
                          value={this.state.instagram}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="basic-url" class="form-label">
                        Linkedin
                      </label>
                      <div class="input-group mb-3">
                        {/* <span class="input-group-text" id="basic-addon3">
                          https://
                        </span> */}
                        <input
                          class="form-control"
                          id="basic-url"
                          aria-describedby="basic-addon3"
                          name="linkedin"
                          type="text"
                          value={this.state.linkedin}
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <br />
                    </div>
                  </div>
                  {/* <div
                    class="form-check"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <input
                      checked={checkedActive}
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      name="checkedActive"
                      value={checkedActive}
                      onChange={this.handleCheck}
                    />
                    <label class="form-check-label" for="exampleCheck1">
                      Active
                    </label>
                  </div> */}
                  <div>
                    {toggleEdit === false ? (
                      <Button
                        disabled={loaderToggle === true ? true : false}
                        type="submit"
                        className="mr-2"
                        color="primary"
                        // onClick={
                        //   () => this.addData()
                        //   // this.setState({
                        //   //   modal: !this.state.modal,
                        //   // })
                        // }
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        disabled={loaderToggle === true ? true : false}
                        type="submit"
                        className="mr-2"
                        color="primary"
                        // onClick={
                        //   () => this.editUser()
                        //   // this.setState({
                        //   //   modal: !this.state.modal,
                        //   // })
                        // }
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      color="secondary"
                      style={{ marginLeft: "0.5rem" }}
                      onClick={() =>
                        this.setState({
                          modal: !this.state.modal,
                          imageEdit: "",
                          image: "",
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </ModalBody>
            </Modal>
          </div>
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
