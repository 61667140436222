import React, { Component } from "react";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import firebase from "../../config/firebase";
import { Link } from "react-router-dom";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      arrData: [],
    };
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    firebase
      .database()
      .ref("Categories")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };
  render() {
    const { title, para, detail, emailLink, arrData } = this.state;
    const pakiDocs = arrData.filter((e) => e.selectType === "Pakistani Law");
    const statutesDocs = arrData.filter((e) => e.selectType === "Statutes");
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>
                      {this.props.match.path
                        .replace(/[#_/-]/g, " ")
                        .toUpperCase()}
                    </h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}

            <section id="blog" class="blog">
              <div class="container" data-aos="fade-up">
                <h2 className="mt-2 mb-2" style={{ textAlign: "left" }}>
                  PAKISTANI LAWS
                </h2>
                <div class="row">
                  {pakiDocs.map((e, i) => {
                    // console.log("e", e);
                    return (
                      e.checkedActive === true && (
                        <div class="col-md-6 mb-4 " key={i}>
                          <Link to={`/detail/${e.userDataKey}`} variant="info">
                            <div class="card ">
                              <div class="card-body">
                                <h5 class="card-title">{e.categoryName}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    );
                  })}
                </div>
                <h2 className="mt-2 mb-2" style={{ textAlign: "left" }}>
                  STATUTES
                </h2>
                <div class="row">
                  {statutesDocs.map((e, i) => {
                    // console.log("e", e);
                    return (
                      e.checkedActive === true && (
                        <div class="col-md-6 mb-4 " key={i}>
                          <Link to={`/detail/${e.userDataKey}`} variant="info">
                            <div class="card ">
                              <div class="card-body">
                                <h5 class="card-title">{e.categoryName}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
        </div>

        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
