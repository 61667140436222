import React, { Component } from "react";
import Navbar from "../../config/NavAdmin";
import firebase from "../../config/firebase";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import renderHTML from "react-render-html";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
var database = firebase.database();
ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "imageUpload",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      checkedActive: false,
      imageChange: false,
      toggleEdit: false,
      arrData: [],
      arrCategoryData: [],
      image: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    this.getData();
    this.getCategoriesData();
  }

  getCategoriesData = () => {
    firebase
      .database()
      .ref("Categories")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrCategoryData: newArr.reverse(),
        });
      });
  };
  getData = () => {
    firebase
      .database()
      .ref("Statutes_Data")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };

  handleChangeImage = (e) => {
    const image = e.target.files[0];
    // console.log("image", image);
    // if (image.type != "application/pdf") {
    //   // alert("Only PDF are allowed");
    //   // this.setState({ imageData: undefined });
    //   //   setError("selectedfile", {
    //   //       type: "filetype",
    //   //       message: "Only PDFs are valid."
    //   //   });
    //   //   return;
    //   this.setState(() => ({ image }));
    // } else {
    //   alert("Only PDF are allowed");
    //   this.setState(() => ({ image }));
    // }
    if (e.target.files[0]) {
      const image = e.target.files[0];

      this.setState(() => ({ image }));
    }
  };
  handleUpdateImage = (e) => {
    if (e.target.files[0]) {
      const imageUpdate = e.target.files[0];

      this.setState(() => ({ imageUpdate }));
    }
  };

  handleCheck = () => {
    this.setState({
      checkedActive: !this.state.checkedActive,
    });
  };
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  addData(e) {
    e.preventDefault();
    const {
      documentName,
      documentLink,
      checkedActive,
      data,
      selectType,
      linkOrAttach,
      image,
      categoryName,
      categoryId,
    } = this.state;

    this.setState({
      loaderToggle: true,
    });
    var splitData = categoryId.split(",");

    var userDataKey = firebase
      .database()
      .ref()
      .child("Careers_Data")
      .push().key;

    const path = "PDF_" + userDataKey;
    // const path = image.name;
    var imageData = image && image.type;
    if (image === null) {
      var obj = {
        documentName,
        documentLink,
        checkedActive,
        categoryName: splitData[1],
        selectType,
        createAt: new Date().toLocaleString(),
        userDataKey,
        linkOrAttach,
        imageName: image.name,
        categoryId: splitData[0],
        // downloadURL,
      };
      // console.log("obj", obj);

      firebase
        .database()
        .ref("Statutes_Data/" + userDataKey)
        .set(obj)
        .then((e) => {
          this.setState({
            // success: "Successfully",
            // visible: true,
            modal: !this.state.modal,
            loaderToggle: false,
          });
        })
        .catch((error) => {
          console.log("Error: ", error.message);
        });
    } else if (imageData != "application/pdf") {
      alert("Only PDF are allowed");
      this.setState({
        loaderToggle: false,
      });
    } else {
      const uploadTask = firebase
        .storage()
        .ref(`Statutes_Data/${path}`)
        .put(image)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
        })

        .then((downloadURL) => {
          // console.log(
          //   `Successfully uploaded file and got download link - ${downloadURL}`
          // );
          // return downloadURL;
          this.setState({ url: downloadURL });
          var obj = {
            documentName,
            documentLink,
            checkedActive,
            categoryName: splitData[1],
            selectType,
            createAt: new Date().toLocaleString(),
            userDataKey,
            linkOrAttach,
            imageName: image.name,
            categoryId: splitData[0],
            downloadURL,
          };
          // console.log("obj", obj);

          firebase
            .database()
            .ref("Statutes_Data/" + userDataKey)
            .set(obj)
            .then((e) => {
              this.setState({
                // success: "Successfully",
                // visible: true,
                modal: !this.state.modal,
                loaderToggle: false,
              });
            })
            .catch((error) => {
              console.log("Error: ", error.message);
            });
        });
    }
  }

  deleteUser = (id, image) => {
    database
      .ref("Statutes_Data/" + id)
      .remove()
      .then((success) => {
        // this.setState({ open: false });
        let name = image.substr(
          image.indexOf("%2F") + 3,
          image.indexOf("?") - (image.indexOf("%2F") + 3)
        );
        name = name.replace("%20", " ");
        // console.log(name);
        let storagePath = firebase.storage().ref();
        storagePath
          .child(`Statutes_Data/${name}`)
          .delete()
          .then(() => {
            // File deleted successfully
            console.log("deleted");
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  editUser(e) {
    e.preventDefault();
    const {
      documentName,
      documentLink,
      checkedActive,
      data,
      selectType,
      linkOrAttach,
      image,
      categoryName,
      userDataKey,
      imageChange,
    } = this.state;

    this.setState({
      loaderToggle: true,
    });

    const path = "PDF_" + userDataKey;
    // const path = image.name;
    var imageData = image !== null && image.type;
    if (imageChange === true) {
      if (imageData != "application/pdf") {
        alert("Only PDF are allowed");
        this.setState({
          loaderToggle: false,
        });
      } else {
        const uploadTask = firebase
          .storage()
          .ref(`Statutes_Data/${path}`)
          .put(image)
          .then((snapshot) => {
            return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
          })

          .then((downloadURL) => {
            // console.log(
            //   `Successfully uploaded file and got download link - ${downloadURL}`
            // );
            // return downloadURL;
            this.setState({ url: downloadURL });
            var obj = {
              documentName,
              documentLink,
              checkedActive,
              categoryName,
              selectType,
              updateAt: new Date().toLocaleString(),
              userDataKey,
              linkOrAttach,
              downloadURL,
              imageName: image.name,
            };
            // console.log("obj", obj);

            firebase
              .database()
              .ref("Statutes_Data/" + userDataKey)
              .update(obj)
              .then((e) => {
                this.setState({
                  // success: "Successfully",
                  // visible: true,
                  modal: !this.state.modal,
                  loaderToggle: false,
                });
              })
              .catch((error) => {
                console.log("Error: ", error.message);
              });
          });
      }
    } else {
      var obj = {
        documentName,
        documentLink,
        checkedActive,
        categoryName,
        selectType,
        updateAt: new Date().toLocaleString(),
        userDataKey,
        // linkOrAttach,
        // downloadURL,
        // imageName: image.name,
      };
      // console.log("obj", obj);

      firebase
        .database()
        .ref("Statutes_Data/" + userDataKey)
        .update(obj)
        .then((e) => {
          this.setState({
            // success: "Successfully",
            // visible: true,
            modal: !this.state.modal,
            loaderToggle: false,
          });
        })
        .catch((error) => {
          console.log("Error: ", error.message);
        });
    }
  }

  render() {
    const {
      loaderToggle,
      arrData,
      toggleEdit,
      checkedActive,
      documentName,
      documentLink,

      data,
      // imageData,
      linkOrAttach,
      image,
      imageEdit,
      selectType,
      imageName,
      imageChange,
      toggleAttachEdit,
    } = this.state;

    // var imageName = this.state.image && this.state.image.name;
    var imageData = imageName ? imageName : imageEdit;

    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>STATUTES | ADMIN</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="doctors" class="doctors">
              <div class="container">
                <div class="d-flex justify-content-end">
                  {/* <button
                    class="btn btn-primary mb-3 "
                    onClick={() =>
                      this.setState({
                        modal: !this.state.modal,
                        documentName: "",
                        documentLink: "",
                        checkedActive: "",
                        linkOrAttach: "",
                        selectType: "",
                        categoryName: "",
                        imageName: "",
                        checkedActive: false,
                        toggleEdit: false,
                        imageChange: true,
                      })
                    }
                  >
                    Add link
                  </button> */}
                  <button
                    style={{ marginLeft: "0.6rem" }}
                    class="btn btn-primary mb-3 "
                    onClick={() =>
                      this.setState({
                        modal: true,
                        documentName: "",
                        documentLink: "",
                        checkedActive: "",
                        linkOrAttach: "",
                        selectType: "",
                        categoryName: "",
                        imageName: "",
                        checkedActive: false,
                        toggleEdit: false,
                        imageChange: true,
                      })
                    }
                  >
                    Add Attachment
                  </button>
                </div>
                <div class="row">
                  <h2 className="mt-2 mb-2" style={{ textAlign: "left" }}>
                    STATUTES
                  </h2>
                  {this.state.arrData.map((e, i) => {
                    // console.log("e", e);
                    return (
                      <div class="col-lg-6 col-md-6 mb-4" key={i}>
                        <div class="card shadow">
                          <div class="card-body">
                            {e.checkedActive === true ? (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  // position: "absolute",
                                  color: "green",
                                }}
                              >
                                Active
                              </p>
                            ) : (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  // position: "absolute",
                                  // fontWeight: 600 ,
                                  color: "red",
                                }}
                              >
                                Not Active
                              </p>
                            )}
                            <h5 class="card-title">{e.documentName}</h5>
                            <p class="card-text">Type: {e.selectType}</p>
                            <p class="card-text">Category: {e.categoryName}</p>
                            <p>Link: {e.imageName}</p>
                          </div>
                          <div class="d-flex justify-content-end ml-4 mb-3 p-3">
                            <button
                              type="button"
                              style={{ marginRight: "0.5rem" }}
                              class="btn btn-primary btn-sm "
                              onClick={() => {
                                this.setState({
                                  documentName: e.documentName,
                                  documentLink: e.documentLink,
                                  checkedActive: e.checkedActive,
                                  selectType: e.selectType,
                                  createAt: e.createAt,
                                  linkOrAttach: e.linkOrAttach,
                                  userDataKey: e.userDataKey,
                                  categoryName: e.categoryName,
                                  imageName: e.imageName,
                                  modal: !this.state.modal,
                                  toggleEdit: true,
                                  imageChange: false,
                                });
                                // this.fileInput.name = imageName;
                              }}
                            >
                              <i
                                class="bi bi-pen"
                                style={
                                  {
                                    // color: "green",
                                    // marginRight: "0.5rem",
                                  }
                                }
                              ></i>{" "}
                              Edit
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              onClick={() => {
                                if (
                                  window.confirm("Are you sure want to Delete?")
                                ) {
                                  this.deleteUser(e.userDataKey, e.downloadURL);
                                }
                              }}
                            >
                              <i
                                class="bi bi-trash fa-2x"
                                // style={{ color: "red" }}
                              ></i>{" "}
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
          <div>
            <Modal
              size="xl"
              isOpen={this.state.modal}
              toggle={this.state.toggle}
              //   className={className}
              //   backdrop={backdrop}
              backdrop="static"
              //   keyboard={keyboard}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: "1rem",
                  marginTop: "1rem",
                }}
              >
                <h5 toggle={this.state.toggle}>
                  {toggleEdit === false ? "Add User" : "Edit User"}
                </h5>
                {loaderToggle && <Spinner color="primary" />}
              </div>
              <hr />
              <ModalBody>
                <form
                  class="row g-3"
                  onSubmit={
                    toggleEdit === false
                      ? this.addData.bind(this)
                      : this.editUser.bind(this)
                  }
                >
                  <div class="col-md-6">
                    <label class="form-label" for="inputEmailAddress">
                      Document Name
                    </label>
                    <input
                      class="form-control "
                      id="inputEmailAddress"
                      placeholder="Enter Job Title"
                      name="documentName"
                      type="text"
                      value={this.state.documentName}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="validationDefault04" class="form-label">
                      {" "}
                      Select Type
                    </label>
                    <select
                      class="form-select"
                      id="validationDefault04"
                      required
                      name="selectType"
                      value={this.state.selectType}
                      onChange={this.handleChange}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option> Pakistani Law</option>
                      <option> Statutes</option>
                    </select>
                  </div>

                  {/* <div class="col-md-4">
                    <label for="validationDefault04" class="form-label">
                      Attachment Or Link
                    </label>
                    <select
                      class="form-select"
                      id="validationDefault04"
                      required
                      name="linkOrAttach"
                      value={this.state.linkOrAttach}
                      // onChange={this.handleChange}
                      onChange={(e) => {
                        var x = e.target.name;
                        this.setState({
                          linkOrAttach: e.target.value,
                          documentLink: "",
                          // image: null,
                        });
                        // this.fileInput.value = "";
                      }}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option>Link</option>
                      <option> Attachment</option>
                    </select>
                  </div> */}
                  <div class="col-md-6">
                    <label for="validationDefault04" class="">
                      {" "}
                      Select Category
                    </label>
                    <select
                      class="form-select"
                      id="validationDefault04"
                      // required
                      name="categoryName"
                      value={this.state.categoryName}
                      // onChange={this.handleChange}
                      onChange={(e) => {
                        this.setState({
                          categoryId: e.target.value,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      {this.state.arrCategoryData.map((e, i) => {
                        return (
                          <option
                            id={e.categoryName}
                            name={e.categoryName}
                            value={`${e.userDataKey}, ${e.categoryName}`}
                          >
                            {e.categoryName}
                          </option>
                        );
                      })}

                      {/* <option> Statutes</option> */}
                    </select>
                  </div>
                  {/* <div class="col-md-4">
                    <label class="" for="inputEmailAddress">
                      Link
                    </label>

                    <input
                      disabled={linkOrAttach === "Link" ? false : true}
                      class="form-control "
                      // id="inputEmailAddress"
                      placeholder="Enter Link"
                      name="documentLink"
                      type="text"
                      value={this.state.documentLink}
                      onChange={this.handleChange}
                      required
                    />
                  </div> */}
                  <div class="col-md-6">
                    <label for="formFile" class="inputEmailAddress">
                      Attachment: {this.state.imageName}
                      {imageName && (
                        <button
                          type="button"
                          class="btn btn-link btn-sm"
                          onClick={() =>
                            this.setState({
                              imageChange: true,
                              // imageEdit: true,
                            })
                          }
                        >
                          Change
                        </button>
                      )}
                    </label>
                    {imageChange === true && (
                      <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        // id="customFile"

                        name={imageName}
                        // name="imageName"
                        // value="{imageData}"
                        onChange={this.handleChangeImage}
                        accept="application/pdf"
                        // required
                        ref={(ref) => (this.fileInput = ref)}
                      />
                    )}
                  </div>
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        checked={checkedActive}
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        name="checkedActive"
                        value={checkedActive}
                        onChange={this.handleCheck}
                        // required
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        Active
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    {toggleEdit === false ? (
                      <Button
                        disabled={loaderToggle === true ? true : false}
                        type="submit"
                        className="mr-2"
                        color="primary"
                        // onClick={
                        //   () => this.addData()
                        //   // this.setState({
                        //   //   modal: !this.state.modal,
                        //   // })
                        // }
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        disabled={loaderToggle === true ? true : false}
                        type="submit"
                        className="mr-2"
                        color="primary"
                        // onClick={
                        //   () => this.editUser()
                        //   // this.setState({
                        //   //   modal: !this.state.modal,
                        //   // })
                        // }
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      disabled={loaderToggle === true ? true : false}
                      style={{ marginLeft: "0.5rem" }}
                      color="secondary"
                      onClick={() =>
                        this.setState({
                          modal: !this.state.modal,
                          // imageEdit: "",
                          // image: "",
                          imageChange: false,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </ModalBody>
            </Modal>
          </div>
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
