import React, { Component } from "react";
// import {} from "react-router"; // Note this extra line
// import { english_lang, chinese_lang, set_lang } from "../store/actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setLocale } from "react-redux-i18n";

/************************* USERS SECTION ******************************/
import logo from "../assets/userLogo.png";
var I18n = require("react-redux-i18n").I18n;
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      arr: [
        {
          id: 1,
          link: "/Banking-and-finance",
          // imageCardBg: Finacebanking,
          name: "home.servicesubHeading1",
        },
        {
          id: 2,
          link: "/company-secretarial",
          // imageCardBg: CompanySecretarialServices,
          name: "home.servicesubHeading2",
        },
        {
          id: 3,
          link: "/commercial",
          // imageCardBg: Corporate,
          name: "home.servicesubHeading3",
        },
        {
          id: 4,
          link: "/corporate",
          // imageCardBg: Corporate,
          name: "home.servicesubHeading4",
        },
        {
          id: 5,
          link: "/dispute-resolution-litigation",
          // imageCardBg: Disputeresolution,
          name: "home.servicesubHeading5",
        },
        {
          id: 6,
          link: "/employment-and-labour",
          // imageCardBg: EmploymentLabour,
          name: "home.servicesubHeading6",
        },
        {
          id: 7,
          link: "/intellectual-property",
          // imageCardBg: IntellectualProperty,
          name: "home.servicesubHeading7",
        },
        {
          id: 8,
          link: "/private-client-services",
          // imageCardBg: PrivateClientsServices,
          name: "home.servicesubHeading8",
        },
        {
          id: 9,
          link: "/property-&-real-estate",
          // imageCardBg: PropertyRealEstate,
          name: "home.servicesubHeading9",
        },
        {
          id: 10,
          link: "/family-law",
          // imageCardBg: FamilyLaw,
          name: "home.servicesubHeading10",
        },
        {
          id: 11,
          link: "/taxation",
          // imageCardBg: TaxationImg,
          name: "home.servicesubHeading11",
        },
        {
          id: 12,
          link: "/ngo",
          // imageCardBg: NGO,
          name: "home.servicesubHeading12",
        },
      ],
    };
  }
  handleChange = (data) => {
    // console.log(data);
    // localStorage.setItem("language", data.value);
    this.props.setLanguage(data);
    window.location.reload();
  };

  openModal = (event) => {
    document.body.classList.add("mobile-nav-active");
    this.setState({ showModal: true });
  };
  hideModal = (event) => {
    // console.log("success");
    document.body.classList.remove("mobile-nav-active");
    this.setState({ showModal: false });
  };

  toggle(key) {
    // console.log(key);
    this.setState((prevState) => {
      return {
        activeAcc: prevState.activeAcc === key ? false : key,
      };
    });
  }
  render() {
    return (
      <div>
        <header id="header" class="fixed-top d-flex align-items-center">
          <div class="container d-flex align-items-center justify-content-between">
            <div class="logo">
              <div>
                <a href="/">
                  <img
                    src={logo}
                    alt="userLogo"
                    style={{
                      height: "100px",
                    }}
                  />
                </a>
              </div>
            </div>

            <nav id="navbar" class="navbar">
              <ul>
                <li>
                  <a href="/firm-overview">{I18n.t("navbar.navHeading1")}</a>
                </li>
                <li class="dropdown">
                  <a href="#">
                    <span>{I18n.t("navbar.navHeading2")}</span>{" "}
                    <i class="bi bi-chevron-down"></i>
                  </a>
                  <ul>
                    {this.state.arr.map((e, i) => {
                      return (
                        <li>
                          <a href={e.link}>
                            {/* {I18n.t("home.servicesubHeading1")} */}
                            {I18n.t(e.name)}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <a href="/our-professionals">
                    {I18n.t("navbar.navHeading3")}
                  </a>
                </li>
                <li>
                  <a href="/statutes"> {I18n.t("navbar.navHeading8")}</a>
                </li>
                <li>
                  <a href="/opportunities"> {I18n.t("navbar.navHeading5")}</a>
                </li>
                <li>
                  <a href="/contact"> {I18n.t("navbar.navHeading6")}</a>
                </li>

                <li class="dropdown">
                  <a href="#">
                    <span> {I18n.t("navbar.navHeading7")}</span>{" "}
                    <i class="bi bi-chevron-down"></i>
                  </a>
                  {/* <i class="bi bi-chevron-down"></i> */}
                  <ul>
                    <li>
                      {/* <a onClick={() => this.props.eng_lang("en")}>English</a> */}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleChange("en")}
                      >
                        English
                      </a>
                    </li>
                    <li>
                      {/* <a onClick={() => this.props.chin_lang("ch")}> */}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleChange("ch")}
                      >
                        Chinese
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li><a class="getstarted" href="about.html">Get Started</a></li> */}
              </ul>
              <i
                class="bi bi-list mobile-nav-toggle"
                onClick={() => this.openModal()}
              ></i>
            </nav>
          </div>
        </header>

        <nav
          class={
            this.state.showModal === true ? "navbar navbar-mobile" : "navbar d-lg-none d-md-none d-sm-none d-xs-none"
          }
        >
          <ul>
            <li>
              <a href="/firm-overview">{I18n.t("navbar.navHeading1")}</a>
            </li>
            {/* <li class={this.state.dropD ? "dropdown" : ""}> */}
            <li class="dropdown">
              <a onClick={() => this.setState({ dropD: !this.state.dropD })}>               
                {I18n.t("navbar.navHeading2")}
                <i class="bi bi-chevron-down"></i>
              </a>

              <ul class={this.state.dropD ? "dropdown-active" : ""}>
                {this.state.arr.map((e, i) => {
                  return (
                    <li>
                      <a href={e.link}>
                        {/* {I18n.t("home.servicesubHeading1")} */}
                        {I18n.t(e.name)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              <a href="/our-professionals"> {I18n.t("navbar.navHeading3")}</a>
            </li>
            <li>
              <a href="/statutes"> {I18n.t("navbar.navHeading8")}</a>
            </li>
            <li>
              <a href="/opportunities"> {I18n.t("navbar.navHeading5")}</a>
            </li>

            <li>
              <a href="/contact"> {I18n.t("navbar.navHeading6")}</a>
            </li>
            <li class="dropdown">
              <a onClick={() => this.setState({ dropD2: !this.state.dropD2 })}>
                {I18n.t("navbar.navHeading7")}
                <i class="bi bi-chevron-down"></i>
              </a>
              <ul class={this.state.dropD2 ? "dropdown-active" : ""}>
                <li>
                  {/* <a onClick={() => this.props.eng_lang("en")}>English</a> */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleChange("en")}
                  >
                    English
                  </a>
                </li>
                <li>
                  {/* <a onClick={() => this.props.chin_lang("ch")}> */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleChange("ch")}
                  >
                    Chinese
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <i
            class={
              this.state.showModal === true
                ? "bi mobile-nav-toggle bi-x"
                : "bi mobile-nav-toggle bi-list"
            }
            style={{ color: "#fff !important" }}
            onClick={() => this.hideModal()}
          ></i>
        </nav>


        <div
          class="mobile-nav-overly"
          style={{ display: this.state.showModal === false ? "none" : "block" }}
        ></div>
      </div>
    );
  }
}

// export default Navbar;
// export default Todo;
const mapStateToProps = (state) => {
  // console.log(state);
  return {
    getReduxData: state.data,
    // userR: state.user,
    // user: state.user,
    // userfb: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // eng_lang: (data) => dispatch(english_lang(data)),
    // chin_lang: (data) => dispatch(chinese_lang(data)),
    setLanguage: (language) => dispatch(setLocale(language)),
  };
};
// export default withStyles(styles)(Login);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
