import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>{I18n.t("home.servicesubHeading5").toUpperCase()}</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}

            <section id="blog" class="blog">
              <div class="container" data-aos="fade-up">
                <div class="row">
                  <div class="col-lg-12 entries">
                    <article class="entry">
                      <div class="entry-content">
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                            {I18n.t("disputeResolution.content1")}
                        </p>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.content2")}
                        </p>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.content3")}
                        </p>

                        <br />
                      </div>
                      <ul style={{ listStyle: "none" }}>
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0a4d8c",
                          }}
                        ></i>
                        {I18n.t("disputeResolution.contentList1")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0a4d8c",
                          }}
                        ></i>
                        {I18n.t("disputeResolution.contentList2")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0a4d8c",
                          }}
                        ></i>
                        {I18n.t("disputeResolution.contentList3")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0a4d8c",
                          }}
                        ></i>
                        {I18n.t("disputeResolution.contentList4")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0a4d8c",
                          }}
                        ></i>
                        {I18n.t("disputeResolution.contentList5")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0a4d8c",
                          }}
                        ></i>
                        {I18n.t("disputeResolution.contentList6")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0a4d8c",
                          }}
                        ></i>
                        {I18n.t("disputeResolution.contentList7")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0a4d8c",
                          }}
                        ></i>
                        {I18n.t("disputeResolution.contentList8")}
                      </li>
                    </ul>
                    <p style={{ textAlign: "justify" }}>
                      {I18n.t("disputeResolution.content4")}
                    </p>
                    <br />
                    <p style={{ textAlign: "justify" }}>
                      {I18n.t("disputeResolution.content5")}
                    </p>
                    </article>
                  </div>
                </div>
              </div>
            </section>

            <section id="clients" class="clients">
              <div class="container">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                             {I18n.t("disputeResolution.heading1")}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.heading1Content1")}
                        </p>

                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                            {I18n.t("disputeResolution.heading2")}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.heading2Content1")}
                        </p>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.heading2Content2")}
                        </p>
                       
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                          {I18n.t("disputeResolution.heading3")}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.heading3Content1")}
                        </p>
                        <br />{" "}
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.heading3Content2")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                         {I18n.t("disputeResolution.heading4")}
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.heading4Content1")}
                        </p>
                        <br/>
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.heading4Content2")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                         {I18n.t("disputeResolution.heading5")}
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p style={{ textAlign: "justify" }}>
                        {I18n.t("disputeResolution.heading5Content1")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
