import React, { Component } from "react";
import "../../App.css";
import Navbar from "../../config/NavAdmin";
import firebase from "../../config/firebase";
export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      checkedActive: false,
      toggleEdit: false,
      arrData: [],
      arrDataAdmin: [],
    };
  }

  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>Dashboard</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
              <div class="container">
                <div class="row">NOT AUTHORIZED</div>
              </div>
            </section>
          </main>
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
