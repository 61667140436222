import React, { Component } from "react";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
var I18n = require("react-redux-i18n").I18n;
export default class propertyRealState extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}

            <section id="breadcrumbs" class="breadcrumbs">
              <div class="breadcrumb-hero">
                <div class="container">
                  <div class="breadcrumb-hero">
                    <h2>{I18n.t("home.servicesubHeading9").toUpperCase()}</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="blog" class="blog">
              <div class="container" data-aos="fade-up">
                <div class="row">
                  <div class="col-lg-12 entries">
                    <article class="entry">
                      <div class="entry-content">
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                          {I18n.t("propertyRealState.content1")}
                        </p>
                        <br />
                        <p
                          // class="text-justify"
                          style={{ textAlign: "justify" }}
                        >
                          {I18n.t("propertyRealState.content2")}
                        </p>
                      </div>
                      <br />
                      <ul style={{ listStyle: "none" }}>
                        <li>
                          {" "}
                          <i class="icofont-check-circled"></i>{" "}
                          {I18n.t("propertyRealState.contentList1")}{" "}
                        </li>
                        <li>
                          {" "}
                          <i class="icofont-check-circled"></i>{" "}
                          {I18n.t("propertyRealState.contentList2")}{" "}
                        </li>
                        <li>
                          {" "}
                          <i class="icofont-check-circled"></i>{" "}
                          {I18n.t("propertyRealState.contentList3")}{" "}
                        </li>
                        <li>
                          {" "}
                          <i class="icofont-check-circled"></i>{" "}
                          {I18n.t("propertyRealState.contentList4")}{" "}
                        </li>
                        <li>
                          {" "}
                          <i class="icofont-check-circled"></i>{" "}
                          {I18n.t("propertyRealState.contentList5")}{" "}
                        </li>
                      </ul>
                    </article>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="bi bi-arrow-up-square-fill"></i>
        </a>
      </div>
    );
  }
}
