import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
/************************* USERS SECTION ******************************/
// import Books from "../screens/User/Books";
import Home from "../screens/User/Main";
import BankingFinance from "../screens/User/bankingFinance";
import Commercial from "../screens/User/Commerical";
import CompanySecretarial from "../screens/User/companySecretarial";
import Corporate from "../screens/User/corporate";
import EmploymentLabour from "../screens/User/employmentLabour";
import PropertyEstate from "../screens/User/propertyEstate";
import privateClientServices from "../screens/User/privateClientServices";
import intellectualProperty from "../screens/User/intellectualProperty";
import disputeResolution from "../screens/User/disputeResolution";
import ourProfessionals from "../screens/User/ourProfessionals";
import firmOverview from "../screens/User/firmOverview";
import contact from "../screens/User/contact";
import Careers from "../screens/User/Careers";
import Statutes from "../screens/User/statutes";
import familyLaw from "../screens/User/familyLaw";
import NGO from "../screens/User/ngo";
import Taxation from "../screens/User/taxation";
import statutesDetail from "../screens/User/statutesDetails";
/************************* USERS SECTION ******************************/
/************************* ADMIN SECTION ******************************/
import Login from "../screens/Admin/Login";
import Dashboard from "../screens/Admin/Dashboard";
import Users from "../screens/Admin/Users";
import Account from "../screens/Admin/Account";
import AddCareers from "../screens/Admin/Careers";
import AddSTATUTES from "../screens/Admin/AddStatutes";
import AddCategory from "../screens/Admin/Categories";
import Settings from "../screens/Admin/settings";
import NotAuthorized from "../screens/Admin/NotAuthorized";
/************************* ADMIN SECTION ******************************/
import ScrollToTop from "react-router-scroll-top";
/* ================ Private Routes ================ */
import PrivateRoute from "./PrivateRoute";

class Routes extends Component {
  render() {
    const { user } = this.props;

    var userEmail = user.user && user.user.user.email;

    // console.log(
    //   "userEmail",
    //   userEmail === "a.zehra@sayyidandshaykh.com" ? "thek hain" : "Galat Hain"
    // );
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/" exact={true} component={Home} />
            {/* <Route path="/" exact={true} component={Users} /> */}

            <Route path="/Login" component={Login} />

            <Route path="/banking-and-finance" component={BankingFinance} />
            <Route path="/commercial" component={Commercial} />
            <Route path="/company-secretarial" component={CompanySecretarial} />
            <Route path="/corporate" component={Corporate} />
            <Route path="/property-&-real-estate" component={PropertyEstate} />
            <Route path="/employment-and-labour" component={EmploymentLabour} />
            <Route
              path="/private-client-services"
              component={privateClientServices}
            />
            <Route
              path="/intellectual-property"
              component={intellectualProperty}
            />
            <Route
              path="/dispute-resolution-litigation"
              component={disputeResolution}
            />
            <Route path="/our-professionals" component={ourProfessionals} />
            <Route path="/firm-overview" component={firmOverview} />
            <Route path="/contact" component={contact} />
            <Route path="/opportunities" component={Careers} />
            <Route path="/statutes" component={Statutes} />
            <Route path="/detail/:id" component={statutesDetail} />
            <Route path="/family-law" component={familyLaw} />
            <Route path="/taxation" component={Taxation} />
            <Route path="/ngo" component={NGO} />

            {/* <Route path="*" component={NoMatch} /> */}
            <PrivateRoute path="/users" component={Users} authed={user.user} />
            <PrivateRoute
              path="/add-careers"
              component={AddCareers}
              authed={user.user}
            />
            <PrivateRoute
              path="/account"
              component={Account}
              authed={user.user}
            />
            <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              authed={user.user}
            />
            {/* {userEmail === "a.zehra@sayyidandshaykh.com" ? ( */}
              <PrivateRoute
                path={
              
                     "/add-statutes"
                    
                }
                component={AddSTATUTES}
                // component={Dashboard}
                authed={user.user}
              />
            {/* // ) : (
            //   <PrivateRoute
            //     path="/not-authorized"
            //     component={NotAuthorized}
            //     // component={Dashboard}
            //     // authed={user.user}
            //   />
            // )} */}
            <PrivateRoute
              path="/categories"
              component={AddCategory}
              // component={Dashboard}
              authed={user.user}
            />
            <PrivateRoute
              path="/setting"
              component={Settings}
              // component={Dashboard}
              authed={user.user}
            />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state.user on route compoinent", state.reducers);
  return {
    user: state.reducers,
  };
};

// export default withStyles(styles)(Login);
export default connect(mapStateToProps, null)(Routes);
